import { Button } from "@blueprintjs/core";
import { FullScreenHandle } from "react-full-screen";
import styles from "./style.module.scss";

function Toolbar(props: {
  handleDownload: () => void;
  fullscreenHandle: FullScreenHandle;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <Button icon="floppy-disk" text="Save" onClick={props.handleDownload} />
        <Button
          icon="minimize"
          text="Exit Fullscreen"
          onClick={props.fullscreenHandle.exit}
        />
      </div>
    </div>
  );
}

export default Toolbar;
