import axios from "axios";
import { Run } from "../constants/types";

async function getPastRuns(ids?: string[]) {
  const url =
    "/api/history/?" + (ids?.map((id) => `svs_ids=${id}`).join("&") ?? "");
  const response = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result: Run[] = JSON.parse(response.data).map((raw: any) => {
    const { time_created, ...rest } = raw;
    return {
      time_created: new Date(time_created),
      ...rest,
    };
  });
  return result;
}

export { getPastRuns };
