import { Button, NonIdealState } from "@blueprintjs/core";
import React from "react";
import { useHistory } from "react-router-dom";

const PageNotFound: React.FC<any> = () => {
  const history = useHistory();
  const description = `We couldn't find the page you requested! If this seems
                       like a bug, please contact the project supervisors
                       through the authors dialog`;

  return (
    <NonIdealState
      title="Uh oh! It's a 404"
      description={description}
      icon="error"
      action={
        <Button
          intent="primary"
          icon="home"
          text="Navigate to Home"
          onClick={() => history.push("/")}
        />
      }
    />
  );
};

export default PageNotFound;
