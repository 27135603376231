import FileSaver from "file-saver";
import { useCallback } from "react";
import { useFullScreenHandle } from "react-full-screen";
import {
  CartesianGrid,
  Scatter as ScatterPlot,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { CHART_MARGIN, CHART_Y_WIDTH } from "../../constants/chart";
import { FeatureClassToColour } from "../../constants/map";
import { FeatureClass } from "../../constants/types";
import { generateXLabel, generateYLabel } from "../../utils/chart";
import Chart from "../Chart";
import ScatterTooltip from "../ScatterTooltip";

function Scatter(props: {
  data: { x: number; y: number }[];
  xName: string;
  yName: string;
  height: number;
  coloured: boolean;
  feature?: FeatureClass;
}) {
  const xlabel = generateXLabel(props.xName);
  const ylabel = generateYLabel(props.yName);
  const [getPng, { ref }] = useCurrentPng();
  const handle = useFullScreenHandle();

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    /* Download with FileSaver */
    if (png !== undefined) {
      FileSaver.saveAs(png, "scatter-plot.png");
    }
  }, [getPng]);

  return (
    <Chart
      height={320}
      handleDownload={handleDownload}
      fullscreenHandle={handle}
    >
      <ScatterChart width={400} height={400} margin={CHART_MARGIN} ref={ref}>
        <CartesianGrid />
        <XAxis dataKey={"x"} label={xlabel} type="number" />
        <YAxis
          width={CHART_Y_WIDTH}
          dataKey={"y"}
          label={ylabel}
          type="number"
        />
        <Tooltip
          cursor={{ strokeDasharray: "3 3" }}
          content={<ScatterTooltip />}
        />
        <ScatterPlot
          name="Scatter Plot"
          data={props.data}
          fill={
            props.coloured && props.feature !== undefined
              ? FeatureClassToColour[props.feature]
              : "black"
          }
          shape="wye"
          lineType="fitting"
          line={{
            stroke: "#333",
            strokeWidth: 1,
            strokeDasharray: "3 3",
          }}
        />
      </ScatterChart>
    </Chart>
  );
}

export default Scatter;
