import { Menu, MenuItem } from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";
import { FullScreen, FullScreenHandle } from "react-full-screen";
import { ResponsiveContainer } from "recharts";
import Toolbar from "../Toolbar";
import styles from "./style.module.scss";

function Chart(props: {
  handleDownload: () => void;
  fullscreenHandle: FullScreenHandle;
  height: number;
  children: React.ReactElement;
}) {
  const handle = props.fullscreenHandle;
  const menu = (
    <Menu>
      <MenuItem icon="floppy-disk" text="Save" onClick={props.handleDownload} />
      <MenuItem icon="fullscreen" text="Fullscreen" onClick={handle.enter} />
    </Menu>
  );

  return (
    <ContextMenu2 content={menu}>
      <FullScreen handle={handle}>
        {handle.active && (
          <Toolbar
            handleDownload={props.handleDownload}
            fullscreenHandle={handle}
          />
        )}
        <div className={handle.active ? styles.fullscreen : styles.container}>
          <ResponsiveContainer
            width={"100%"}
            height={handle.active ? "100%" : props.height}
          >
            {props.children}
          </ResponsiveContainer>
        </div>
      </FullScreen>
    </ContextMenu2>
  );
}

export default Chart;
