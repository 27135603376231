import axios from "axios";

function generateStatistics(
  svsId: string,
  annotation: any,
  feature_type: string,
  feature_region: string
) {
  const data = {
    annotation: annotation,
    feature_type: feature_type,
    feature_region: feature_region,
  };

  return axios.post(`/api/generate-stats/` + svsId, data);
}

export { generateStatistics };
