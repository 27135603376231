import { Alignment, Button, Classes, Navbar } from "@blueprintjs/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import CreditsPopup from "../CreditsPopup";
import styles from "./style.module.scss";

const NavigationBar: React.FC<any> = () => {
  const history = useHistory();
  const [creditsPopupOpen, setCreditsPopupOpen] = useState(false);

  return (
    <>
      <Navbar id="navbar" className={Classes.DARK} role="navigation">
        <Navbar.Group align={Alignment.LEFT}>
          <img
            className={styles.logo}
            src={"/assets/microscope.png"}
            alt="Microscope Emoji"
          />
          {/* Users tend to click on a website's branding to navigate back to
           * the main context. This is an implicit link, whereas the "Home"
           * button is an explicit one. Ref: https://bit.ly/3zgXsno */}
          <Navbar.Heading
            style={{ fontWeight: 600, cursor: "pointer" }}
            onClick={() => history.push("/")}
          >
            KidneyCaliper
          </Navbar.Heading>
          <Navbar.Divider />
          <Button
            className={Classes.MINIMAL}
            icon="home"
            text="Home"
            onClick={() => history.push("/")}
          />
          <Button
            className={Classes.MINIMAL}
            icon="panel-stats"
            text="Results"
            onClick={() => history.push("/past-runs/")}
          />
          <Button
            className={Classes.MINIMAL}
            icon="scatter-plot"
            text="Cross Comparison"
            onClick={() => history.push("/cross-comparison/")}
          />
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Button
            className={Classes.MINIMAL}
            icon="people"
            text="Authors"
            onClick={() => setCreditsPopupOpen((isOpen) => !isOpen)}
          />
        </Navbar.Group>
      </Navbar>
      <CreditsPopup
        isOpen={creditsPopupOpen}
        onClose={() => setCreditsPopupOpen(false)}
      />
    </>
  );
};

export default NavigationBar;
