import axios from "axios";

function uploadWSI(files: FileList, isPrivate: boolean) {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i);
    if (file !== null) {
      formData.append("files", file, file.name);
    }
  }
  return axios.post(`/api/upload-svs/?private=${isPrivate}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function uploadAnnotations(svsId: string, annotations: any) {
  return axios.post(`/api/upload-annotations/${svsId}`, annotations);
}

function requestProcessing(svsId: string) {
  return axios.post(`/api/request-processing/${svsId}`);
}

export { uploadWSI, uploadAnnotations, requestProcessing };
