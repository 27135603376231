import { readRemoteFile } from "react-papaparse";

async function parseCSV(url: string) {
  return new Promise<any>((resolve) => {
    readRemoteFile(url, {
      download: true,
      header: true,
      complete: resolve,
    });
  });
}

export { parseCSV };
