import { MenuDivider, MenuItem } from "@blueprintjs/core";

function ExportMenu(props: { id: string }) {
  return (
    <>
      <MenuDivider title="Annotations" />
      <MenuItem
        icon="polygon-filter"
        text="GeoJSON Annotations"
        href={`/api/geojson/${props.id}`}
        download={props.id + ".geojson"}
      />
      <MenuItem
        icon="polygon-filter"
        text="W3C Web Annotations"
        href={`/api/w3c-annotations/${props.id}`}
        download={props.id + ".w3c.json"}
      />
      <MenuDivider title="Statistics" />
      <MenuItem
        icon="th"
        text="Feature Statistics CSV"
        href={`/api/feature-statistics/${props.id}`}
        download={props.id + "-features.csv"}
      />
      <MenuItem
        icon="th"
        text="Region Statistics CSV"
        href={`/api/region-statistics/${props.id}`}
        download={props.id + "-regions.csv"}
      />
      <MenuItem
        icon="th"
        text="Whole Slide Statistics CSV"
        href={`/api/whole-slide-statistics/${props.id}`}
        download={props.id + "-whole-slide.csv"}
      />
    </>
  );
}

export default ExportMenu;
