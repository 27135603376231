export type ContributorDetails = [string, string?, string?];

export type Department = {
  link: string;
  imgLink: string;
  deptName: string;
  deptInstitution: string;
};

export type FeatureClass =
  | "Tubulus"
  | "Vessel Indeterminate"
  | "Artery"
  | "Glomerulus";

export type StatisticName =
  | "Area of Feature (μm²)"
  | "Centroid X (μm)"
  | "Centroid Y (μm)"
  | "Centroid X (pixels)"
  | "Centroid Y (pixels)"
  | "Perimeter of Feature (μm)"
  | "Area of Maximum Inscribed Circle (μm²)"
  | "Radius of Maximum Inscribed Circle (μm)"
  | "Maximum Inscribed Circle Center X (μm)"
  | "Maximum Inscribed Circle Center Y (μm)"
  | "Radius of Maximum Inscribed Circle (pixels)"
  | "Maximum Inscribed Circle Center X (pixels)"
  | "Maximum Inscribed Circle Center Y (pixels)"
  | "Circumference of Maximum Inscribed Circle (μm)";

export type RegionClass =
  | "Cortex"
  | "Medulla"
  | "Non-Kidney"
  | "Transition"
  | "Other";

export type RegionStatisticName =
  | "Area of Region (μm²)"
  | "Centroid X (μm)"
  | "Centroid Y (μm)"
  | "Centroid X (pixels)"
  | "Centroid Y (pixels)"
  | "Perimeter of Region (μm)";

export type FeatureStatistics = {
  [key: string]: FeatureClass | RegionClass | number;
  classification: FeatureClass;
  region: RegionClass;
  computed_area_micron_sq: number;
  centroid_x_micron: number;
  centroid_y_micron: number;
  centroid_x_pixel: number;
  centroid_y_pixel: number;
  perimeter_micron: number;
  max_inscribed_circle_computed_area_micron_sq: number;
  max_inscribed_circle_radius_micron: number;
  max_inscribed_circle_center_x_micron: number;
  max_inscribed_circle_center_y_micron: number;
  max_inscribed_circle_radius_pixel: number;
  max_inscribed_circle_center_x_pixel: number;
  max_inscribed_circle_center_y_pixel: number;
  max_inscribed_circle_perimeter_micron: number;
};

export type Reduction = "Mean" | "Median" | "Total";

export type MetadataStatistics = "total_area";

export type WholeSlideStatisticPath =
  | {
      classification: FeatureClass;
      statName: StatisticName | "Count";
      reduction?: Reduction;
    }
  | {
      classification: RegionClass;
      statName: RegionStatisticName | "Area fraction";
      reduction?: Reduction;
    }
  | {
      classification: "Metadata";
      statName: MetadataStatistics;
      reduction?: Reduction;
    };

export type MapTree<Leaf> = Map<
  string,
  { leaf: Leaf } | { children: MapTree<Leaf> }
>;

export type WholeSlideStats = MapTree<number>;

export type FeatureAndRegion = {
  feature: string;
  region: string;
};

export type AnnotationBodyElem = {
  type: string;
  purpose: string;
  value: string;
};

export type Annotation = {
  "@context": string;
  id: string;
  type: string;
  body: AnnotationBodyElem[];
  target: {
    selector: {
      type: string;
      value: string;
    };
  };
};

export const FeatureKey = [
  "Tubuli",
  "Vessel_indeterminate",
  "Artery",
  "Glomeruli",
  "Kidney region",
] as const;

export const RegionKey = [
  "CORTEX",
  "MEDULLA",
  "TRANSITION",
  "NON_KIDNEY",
] as const;

export type Feature = typeof FeatureKey[number];

export type Region = typeof RegionKey[number];

export type FilterName = Feature | Region | "inscribed circle";

export type RunRowInfo = Run & { queue_position?: number };

export type ViewID = "a" | "b";

export type ProcessingStatus =
  | "UPLOADED"
  | "SCHEDULED"
  | "IN_PROGRESS"
  | "COMPLETED";

export type Run = {
  id: string;
  progress: number;
  processing_status: ProcessingStatus;
  time_created: Date;
  filename: string;
  original_id: string;
};
