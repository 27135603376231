import {
  Button,
  ButtonGroup,
  Divider,
  H5,
  Menu,
  MenuDivider,
  mergeRefs,
  Switch,
} from "@blueprintjs/core";
import { Classes as Classes2, Popover2 } from "@blueprintjs/popover2";
import * as Annotorious from "@recogito/annotorious-openseadragon";
import * as SelectorPack from "@recogito/annotorious-selector-pack";
import axios from "axios";
import OpenSeaDragon from "openseadragon";
import React from "react";
import { getDZIImage } from "../../api/dzi";
import { generateStatistics } from "../../api/statistics";
import { uploadAnnotations } from "../../api/upload";
import {
  FeatureKeyToColour,
  FeatureKeyToFeatureClass,
  RegionKeyToColour,
  RegionKeyToRegionClass,
} from "../../constants/map";
import {
  Annotation,
  Feature,
  FeatureAndRegion,
  FeatureKey,
  FilterName,
  Region,
  RegionKey,
} from "../../constants/types";
import { MeasurementWidget } from "../MeasurementWidget";
import "./annotorious.scss";
import styles from "./style.module.scss";

type Props = {
  svsId: string;
  updateStats: () => Promise<void>;
  updateSvsId: (svsId: string, then?: () => void) => void;
};

type State = {
  uploadingAnnotations: boolean;
  annotationTypes: any;
  filter: { [key in FilterName]: boolean };
  changesToSubmit: boolean;
  polygonEnabled: boolean;
  freehandEnabled: boolean;
};

class ImageViewer extends React.Component<Props, State> {
  private _anno: any = undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      uploadingAnnotations: false,
      annotationTypes: {
        other: {
          other: [],
        },
      },
      filter: {
        Tubuli: false,
        Vessel_indeterminate: false,
        Artery: true,
        Glomeruli: true,
        "inscribed circle": false,
        CORTEX: true,
        MEDULLA: false,
        TRANSITION: false,
        NON_KIDNEY: false,
        "Kidney region": true,
      },
      changesToSubmit: false,
      polygonEnabled: false,
      freehandEnabled: false,
    };
    this.initAnnotations = this.initAnnotations.bind(this);
    this.filterAnnotations = this.filterAnnotations.bind(this);
    this.filterCircle = this.filterCircle.bind(this);
    this.addAllAnnotations = this.addAllAnnotations.bind(this);
    this.removeAllAnnotations = this.removeAllAnnotations.bind(this);
    this.createAnnotation = this.createAnnotation.bind(this);
    this.deleteAnnotation = this.deleteAnnotation.bind(this);
    this.submitAnnotations = this.submitAnnotations.bind(this);
    this.updateAnnotation = this.updateAnnotation.bind(this);
    this.getAnnotationArray = this.getAnnotationArray.bind(this);
    this.updateAnnotationArray = this.updateAnnotationArray.bind(this);
    this.enableDraw = this.enableDraw.bind(this);
  }

  async componentDidMount() {
    const dziImageId = await getDZIImage(this.props.svsId);
    const viewer = OpenSeaDragon({
      id: "openSeaDragon",
      tileSources: `/api/dzi/${dziImageId.data.id}.dzi`,
      gestureSettingsMouse: {
        clickToZoom: false,
      },
      zoomInButton: "zoom-in-button",
      zoomOutButton: "zoom-out-button",
      homeButton: "home-button",
      fullPageButton: "full-page-button",
    });

    const formatter = (annotation: Annotation) => {
      const { feature, region } = this.getFeatureTypeAndRegion(annotation);
      let color;
      if (feature !== undefined && feature.includes("inscribed circle")) {
        color = "#752F75";
      } else if (feature !== undefined && region !== undefined) {
        color =
          FeatureKeyToColour[feature as Feature] ??
          RegionKeyToColour[region as Region];
      } else {
        color = "#30404D";
      }

      return {
        style: `stroke-width:2;
          stroke-opacity:0.8;
          stroke: ${color};
          fill-opacity: 0.35;
          fill: ${feature === "Kidney region" ? "none" : color}`,
      };
    };

    const config = {
      formatter: formatter,
    };

    const anno = Annotorious(viewer, config);
    SelectorPack(anno, {
      tools: ["polygon", "freehand"],
    });
    anno.removeDrawingTool("rect");
    anno.on("createAnnotation", this.createAnnotation);
    anno.on("deleteAnnotation", this.deleteAnnotation);
    anno.on("updateAnnotation", this.updateAnnotation);
    anno.on("createSelection", () => {
      this.setState({ freehandEnabled: false, polygonEnabled: false });
    });

    this._anno = anno;

    axios.get(`/api/w3c-annotations/${this.props.svsId}`).then((response) => {
      const tags = this.initAnnotations(response.data);
      /* On build, the compiler optimises away something or reorders "return" and
       * "createElement" statements that causes Annotorious's getWidget function
       * to consider a plainjs widget as a React Functional component; refer to
       * the comment in MeasurementWidget/index.js */
      anno.widgets = [
        process.env.NODE_ENV === "production"
          ? MeasurementWidget
          : (args: any) => MeasurementWidget(args),
        { widget: "TAG", vocabulary: Array.from(tags) },
      ];
    }, console.error);
  }

  enableDraw(tool: string) {
    if (tool === "polygon") {
      this.setState({ polygonEnabled: true });
    } else {
      this.setState({ freehandEnabled: true });
    }
    this._anno.setDrawingTool(tool);
    this._anno.setDrawingEnabled(true);
  }

  render() {
    return (
      <div className={styles.pane}>
        <div className={styles.toolbar}>
          <ButtonGroup>
            <Button
              id="polygon-button"
              icon="polygon-filter"
              onClick={() => this.enableDraw("polygon")}
              active={this.state.polygonEnabled}
            />
            <Button
              id="freehand-button"
              icon="draw"
              onClick={() => this.enableDraw("freehand")}
              active={this.state.freehandEnabled}
            />
            <Divider />
            <Button
              className={styles.viewerButton}
              icon="zoom-in"
              id="zoom-in-button"
            />
            <Button
              className={styles.viewerButton}
              icon="zoom-out"
              id="zoom-out-button"
            />
            <Button
              className={styles.viewerButton}
              icon="zoom-to-fit"
              id="home-button"
            />
            <Button
              className={styles.viewerButton}
              icon="fullscreen"
              id="full-page-button"
            />
            <Divider />
            <Popover2
              content={
                <Menu>
                  <MenuDivider title="Features" className={styles.divider} />
                  {FeatureKey.filter(
                    (feature) => feature !== "Kidney region"
                  ).map((feature, index) => (
                    <div className={styles.switchWrapper}>
                      <Switch
                        className={styles.switch}
                        checked={this.state.filter[feature]}
                        label={FeatureKeyToFeatureClass[feature]}
                        onChange={() => this.filterAnnotations(feature, false)}
                        key={index}
                        alignIndicator="right"
                      />
                      <div
                        className={styles.dot}
                        style={{
                          backgroundColor:
                            FeatureKeyToColour[feature as Feature],
                        }}
                      />
                    </div>
                  ))}
                  <MenuDivider title="Regions" className={styles.divider} />
                  {RegionKey.map((region, index) => (
                    <div className={styles.switchWrapper}>
                      <Switch
                        className={styles.switch}
                        checked={this.state.filter[region]}
                        label={RegionKeyToRegionClass[region]}
                        onChange={() => this.filterAnnotations(region, true)}
                        key={FeatureKey.length - 1 + index}
                        alignIndicator="right"
                      />
                      <div
                        className={styles.dot}
                        style={{
                          backgroundColor: RegionKeyToColour[region as Region],
                        }}
                      />
                    </div>
                  ))}
                  <MenuDivider title="Other" className={styles.divider} />
                  <div className={styles.switchWrapper}>
                    <Switch
                      className={styles.switch}
                      checked={this.state.filter["inscribed circle"]}
                      label="Inscribed Circle"
                      onChange={this.filterCircle}
                      key={FeatureKey.length - 1 + RegionKey.length}
                      alignIndicator="right"
                    />
                    <div
                      className={styles.dot}
                      style={{
                        backgroundColor: "#752F75",
                      }}
                    />
                  </div>
                  <div className={styles.switchWrapper}>
                    <Switch
                      className={styles.switch}
                      checked={this.state.filter["Kidney region"]}
                      label="Kidney Region"
                      onChange={() =>
                        this.filterAnnotations("Kidney region", false)
                      }
                      key={FeatureKey.length + RegionKey.length}
                      alignIndicator="right"
                    />
                    <div
                      className={styles.dot}
                      style={{
                        backgroundColor: "#EEEEEE",
                      }}
                    />
                  </div>
                </Menu>
              }
              interactionKind="click"
              placement="bottom-start"
              renderTarget={({ isOpen, ref, ...p }) => (
                <Button
                  {...p}
                  icon="filter"
                  active={isOpen}
                  elementRef={mergeRefs(ref)}
                />
              )}
            />
          </ButtonGroup>
          <Popover2
            popoverClassName={Classes2.POPOVER2_CONTENT_SIZING}
            interactionKind="click"
            placement="bottom-start"
            enforceFocus={false}
            canEscapeKeyClose={true}
            content={
              <div key="text">
                <H5>Confirm commit</H5>
                <p>
                  Are you sure you want to commit these annotations? The
                  original file will be duplicated with your changes applied on
                  top.
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 15,
                  }}
                >
                  <Button
                    className={Classes2.POPOVER2_DISMISS}
                    style={{ marginRight: 10 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    intent="danger"
                    className={Classes2.POPOVER2_DISMISS}
                    onClick={this.submitAnnotations}
                  >
                    Commit
                  </Button>
                </div>
              </div>
            }
            renderTarget={({ isOpen, ref, ...p }) => (
              <Button
                {...p}
                icon="floppy-disk"
                text={
                  this.state.uploadingAnnotations
                    ? "Updating..."
                    : "Commit Changes"
                }
                disabled={
                  !this.state.changesToSubmit || this.state.uploadingAnnotations
                }
                active={isOpen}
                elementRef={mergeRefs(ref)}
              />
            )}
          />
        </div>
        <div
          className={styles.viewer}
          id="openSeaDragon"
          style={{
            height: "100%",
            boxSizing: "border-box",
            margin: "1rem 1rem",
          }}
        />
      </div>
    );
  }

  upload(id: string, annotations: any) {
    uploadAnnotations(id, annotations).then(
      (response) => {
        window.history.pushState({}, "", `/result/${response.data.id}`);
        this.props.updateSvsId(response.data.id, () =>
          this.props
            .updateStats()
            .then(() => this.setState({ uploadingAnnotations: false }))
        );
      },
      (error) => alert(error)
    );
  }

  getAnnotationArray(annotation: Annotation): [Annotation[], string, string] {
    const { feature, region } = this.getFeatureTypeAndRegion(annotation);

    if (feature === undefined || region === undefined) {
      return [[...this.state.annotationTypes.other.other], "other", "other"];
    } else {
      return [
        [...this.state.annotationTypes[region][feature]],
        region,
        feature,
      ];
    }
  }

  updateAnnotationArray(annotations: Annotation[], key1: string, key2: string) {
    this.setState((state) => {
      return {
        annotationTypes: {
          ...state.annotationTypes,
          [key1]: {
            ...state.annotationTypes[key1],
            [key2]: annotations,
          },
        },
        changesToSubmit: true,
      };
    });
  }

  async createAnnotation(annotation: Annotation) {
    let [annotationsToUpdate, key1, key2] = this.getAnnotationArray(annotation);
    annotationsToUpdate.push(annotation);
    this.updateAnnotationArray(annotationsToUpdate, key1, key2);

    let { feature, region } = this.getFeatureTypeAndRegion(annotation);

    feature = feature || "";
    region = region || "";

    const response = await generateStatistics(
      this.props.svsId,
      annotation,
      feature,
      region
    );

    const inscribed_circle = response.data.inscribed_circle;

    if (
      this.state.filter["inscribed circle"] ||
      feature === "" ||
      region === ""
    ) {
      this._anno.addAnnotation(inscribed_circle);
    }

    for (let measurement in response.data.stats) {
      let value = response.data.stats[measurement];
      annotation.body.push({
        type: "TextualBody",
        purpose: "commenting",
        value: `${measurement}: ${value}`,
      });
    }

    [annotationsToUpdate, key1, key2] =
      this.getAnnotationArray(inscribed_circle);
    annotationsToUpdate.push(inscribed_circle);
    this.updateAnnotationArray(annotationsToUpdate, key1, key2);
  }

  deleteAnnotation(annotation: Annotation) {
    const [annotationsToUpdate, key1, key2] =
      this.getAnnotationArray(annotation);
    const annotationIndex = annotationsToUpdate.findIndex(
      (other: Annotation) => other.id === annotation.id
    );
    annotationsToUpdate.splice(annotationIndex, 1);
    this.updateAnnotationArray(annotationsToUpdate, key1, key2);
  }

  updateAnnotation(annotation: Annotation, previous: Annotation) {
    let [annotationsToUpdate, key1, key2] = this.getAnnotationArray(previous);
    const annotationIndex = annotationsToUpdate.findIndex(
      (other: Annotation) => other.id === previous.id
    );
    // Delete the previous annotation
    this._anno.removeAnnotation(previous);
    annotationsToUpdate.splice(annotationIndex, 1);
    this.updateAnnotationArray(annotationsToUpdate, key1, key2);

    // Add the new annotation
    [annotationsToUpdate, key1, key2] = this.getAnnotationArray(annotation);
    this._anno.addAnnotation(annotation);
    annotationsToUpdate.push(annotation);
    this.updateAnnotationArray(annotationsToUpdate, key1, key2);
  }

  submitAnnotations() {
    this.setState({ uploadingAnnotations: true });
    if (this._anno !== undefined && this.state.changesToSubmit) {
      let annotations: Annotation[] = [];
      RegionKey.forEach((region) => {
        FeatureKey.forEach((feature) => {
          annotations = annotations.concat(
            this.state.annotationTypes[region][feature]
          );
          annotations = annotations.concat(
            this.state.annotationTypes[region][`${feature} inscribed circle`]
          );
        });
      });

      annotations = annotations.concat(this.state.annotationTypes.other.other);

      this.upload(this.props.svsId, annotations);
    }

    this.setState({ changesToSubmit: false });
  }

  getTags(annotation: Annotation): string[] {
    return annotation.body
      .filter(
        (body) => body.type === "TextualBody" && body.purpose === "tagging"
      )
      .map((body) => body.value);
  }

  isFeatureName(name: string): boolean {
    return (
      FeatureKey.includes(name as Feature) ||
      FeatureKey.map((feature) => `${feature} inscribed circle`).includes(
        name
      ) ||
      name === "inscribed circle"
    );
  }

  isRegionName(name: string): name is Region {
    return RegionKey.includes(name as Region);
  }

  getFeatureTypeAndRegion(annotation: Annotation): FeatureAndRegion {
    const tags = this.getTags(annotation);
    const feature_type_tags = tags.filter(this.isFeatureName);
    const feature_region_tags = tags.filter(this.isRegionName);

    return {
      feature: feature_type_tags[0],
      region: feature_region_tags[0],
    };
  }

  annotationHasFeature(
    annotation: Annotation,
    feature_type_query: string
  ): boolean {
    return (
      feature_type_query === this.getFeatureTypeAndRegion(annotation).feature
    );
  }

  annotationHasRegion(annotation: Annotation, region_query: string): boolean {
    return region_query === this.getFeatureTypeAndRegion(annotation).region;
  }

  initAnnotations(annotationJson: Annotation[]) {
    const annotationTypes: any = {
      other: {
        other: [],
      },
    };

    RegionKey.forEach((region) => {
      const annotationRegionTypes: any = {};

      FeatureKey.forEach((feature) => {
        const annotationRegion = annotationJson.filter((annotation) =>
          this.annotationHasRegion(annotation, region)
        );
        annotationRegionTypes[feature] = annotationRegion.filter((annotation) =>
          this.annotationHasFeature(annotation, feature)
        );
        annotationRegionTypes[`${feature} inscribed circle`] =
          annotationRegion.filter((annotation) =>
            this.annotationHasFeature(annotation, `${feature} inscribed circle`)
          );
      });

      annotationTypes[region] = annotationRegionTypes;
    });

    const otherAnnotations = annotationJson.filter((annotation) => {
      const typeRegion = this.getFeatureTypeAndRegion(annotation);
      return (
        typeRegion.feature === undefined || typeRegion.region === undefined
      );
    });

    annotationTypes.other.other = otherAnnotations;
    this.addAllAnnotations(otherAnnotations);

    this.setState({ annotationTypes: annotationTypes }, () => {
      FeatureKey.forEach((feature) => {
        this.filterAnnotations(feature, false, false);
      });
    });

    const tags = annotationJson.flatMap(this.getTags);

    return new Set(tags);
  }

  addAllAnnotations(annotations: Annotation[]) {
    annotations.forEach((annotation: Annotation) =>
      this._anno.addAnnotation(annotation)
    );
  }

  removeAllAnnotations(annotations: Annotation[]) {
    annotations.forEach((annotation: Annotation) =>
      this._anno.removeAnnotation(annotation)
    );
  }

  filterAnnotations(
    filterName: FilterName,
    isRegion: boolean,
    updateState: boolean = true
  ) {
    const filters: readonly FilterName[] = isRegion ? FeatureKey : RegionKey;
    this.setState(
      (state) => {
        const newFilter = { ...this.state.filter };
        newFilter[filterName] = !newFilter[filterName];
        return { filter: updateState ? newFilter : state.filter };
      },
      () => {
        if (this.state.filter[filterName]) {
          filters
            .filter((f) => this.state.filter[f])
            .forEach((f) => {
              const region = isRegion ? filterName : f;
              const feature = isRegion ? f : filterName;
              this.addAllAnnotations(
                this.state.annotationTypes[region][feature]
              );
              if (this.state.filter["inscribed circle"]) {
                this.addAllAnnotations(
                  this.state.annotationTypes[region][
                    `${feature} inscribed circle`
                  ]
                );
              }
            });
        } else {
          filters.forEach((f) => {
            const region = isRegion ? filterName : f;
            const feature = isRegion ? f : filterName;
            this.removeAllAnnotations(
              this.state.annotationTypes[region][feature]
            );
            this.removeAllAnnotations(
              this.state.annotationTypes[region][`${feature} inscribed circle`]
            );
          });
        }
      }
    );
  }

  filterCircle() {
    this.setState(
      () => {
        const newFilter = { ...this.state.filter };
        newFilter["inscribed circle"] = !newFilter["inscribed circle"];
        return { filter: newFilter };
      },
      () => {
        if (this.state.filter["inscribed circle"]) {
          RegionKey.filter((region) => this.state.filter[region]).forEach(
            (region) =>
              FeatureKey.filter(
                (feature) => this.state.filter[feature]
              ).forEach((feature) =>
                this.addAllAnnotations(
                  this.state.annotationTypes[region][
                    `${feature} inscribed circle`
                  ]
                )
              )
          );
        } else {
          RegionKey.forEach((region) =>
            FeatureKey.forEach((feature) =>
              this.removeAllAnnotations(
                this.state.annotationTypes[region][
                  `${feature} inscribed circle`
                ]
              )
            )
          );
        }
      }
    );
  }
}

export default ImageViewer;
