export const BAR_COLORS = [
  "#1F4B99",
  "#356B9D",
  "#5189A0",
  "#76A6A2",
  "#A4C1A2",
  "#DED9A0",
  "#FAD28B",
  "#ECB067",
  "#DC8F49",
  "#C96F2F",
  "#B44E1B",
  "#9E2B0E",
];

export const PIE_COLORS = ["#C23030", "#D9822B", "#0D8050", "#106BA3"];

export const CHART_MARGIN = { top: 24, right: 20, left: 10, bottom: 16 };

export const CHART_HEIGHT = 240;

export const CHART_WIDTH = 480;

export const CHART_Y_WIDTH = 64;
