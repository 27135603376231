export const generateXLabel = (name: string) => {
  return {
    value: name,
    offset: -10,
    position: "insideBottom",
  };
};

export const generateYLabel = (name: string) => {
  return {
    value: name,
    offset: 8,
    angle: -90,
    position: "insideLeft",
    style: { textAnchor: "middle" },
  };
};
