type LabelProps = {
  cx: number;
  cy: number;
  midAngle: number;
  outerRadius: number;
  fill: string;
  payload: any;
  value: number;
};

function PieLabel(props: LabelProps) {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);

  /* Positions for SVG line that points to the pie chart on hover */
  const startx = cx + (outerRadius + 10) * cos;
  const starty = cy + (outerRadius + 10) * sin;
  const middlex = cx + (outerRadius + 20) * cos;
  const middley = cy + (outerRadius + 20) * sin;
  const endx = middlex + (cos >= 0 ? 1 : -1) * 12;
  const endy = middley;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <path
        d={`M${startx},${starty}L${middlex},${middley}L${endx},${endy}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={endx} cy={endy} r={2} fill={fill} stroke="none" />
      <text
        x={endx + (cos >= 0 ? 1 : -1) * 12}
        y={endy + 5}
        textAnchor={textAnchor}
        fill="#333"
      >
        <tspan style={{ color: "black", fontWeight: 600 }}>{value} </tspan>
        {payload.name}
      </text>
    </g>
  );
}

export default PieLabel;
