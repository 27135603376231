import * as d3 from "d3-array";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";
import { UseCurrentPng, useCurrentPng } from "recharts-to-png";
import {
  BAR_COLORS,
  CHART_HEIGHT,
  CHART_MARGIN,
  CHART_WIDTH,
  CHART_Y_WIDTH,
} from "../../constants/chart";
import { FeatureStatistics } from "../../constants/types";
import { generateXLabel, generateYLabel } from "../../utils/chart";
import Chart from "../Chart";

function Adjacency(props: { features: FeatureStatistics[] }) {
  const distances: number[][] = [];
  for (let i = 0; i < props.features.length; i++) {
    const inner: number[] = [];
    for (let j = 0; j < props.features.length; j++) {
      if (i === j) {
        continue;
      }
      const xDiff =
        props.features[i].centroid_x_micron -
        props.features[j].centroid_x_micron;
      const yDiff =
        props.features[i].centroid_y_micron -
        props.features[j].centroid_y_micron;
      const dist = Math.sqrt(xDiff ** 2 + yDiff ** 2);
      inner.push(dist);
    }
    distances.push(inner);
  }

  const truncate = (val?: number) => val?.toFixed(2).replace(/\.0+$/, "");

  const histoData = (reducer: any) => {
    /* You will be tempted to make the map point free; don't because it'll cause
     * a runtime error when reducer = d3.mean */
    const reduced = distances.map((v) => reducer(v)) as number[];
    const bins = d3.bin()(reduced);
    return bins.map((bin) => ({
      range: `${truncate(bin.x0)}-${truncate(bin.x1)}`,
      count: bin.length,
    }));
  };

  const ylabel = generateYLabel("Predicted Count");

  const useMultiplePngs = (currentPngs: UseCurrentPng[]) => {
    const getPngs: (() => Promise<string | undefined>)[] = [];
    const refs: React.MutableRefObject<any>[] = [];
    currentPngs.forEach(function ([getPng, { ref }]) {
      getPngs.push(getPng);
      refs.push(ref);
    });
    return { getPngs, refs };
  };

  const currentPngs = [useCurrentPng(), useCurrentPng()];
  const { getPngs, refs } = useMultiplePngs(currentPngs);

  const handles = [useFullScreenHandle(), useFullScreenHandle()];

  const handleDownload = useCallback(
    async (index: number) => {
      const png = await getPngs[index]();

      if (png !== undefined) {
        // Download with FileSaver
        FileSaver.saveAs(png, "scatter-plot.png");
      }
    },
    [getPngs]
  );

  return (
    <>
      {/* 1. Predicted Count vs. Mean Distances Between Object Centres */}
      <Chart
        handleDownload={() => handleDownload(0)}
        fullscreenHandle={handles[0]}
        height={CHART_HEIGHT}
      >
        <BarChart
          width={CHART_WIDTH}
          height={CHART_HEIGHT}
          data={histoData(d3.mean)}
          margin={CHART_MARGIN}
          ref={refs[0]}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            label={generateXLabel("Mean Distances Between Object Centres (μm)")}
          />
          <YAxis width={CHART_Y_WIDTH} dataKey="count" label={ylabel} />
          <Bar
            dataKey="count"
            label={{ position: "top", style: { fill: "black" } }}
          >
            {histoData(d3.mean).map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={BAR_COLORS[index % 20]} />
            ))}
          </Bar>
        </BarChart>
      </Chart>

      {/* 2. Predicted Count vs. Minimum Distances Between Object Centres */}
      <Chart
        handleDownload={() => handleDownload(1)}
        fullscreenHandle={handles[1]}
        height={CHART_HEIGHT}
      >
        <BarChart
          width={CHART_WIDTH}
          height={CHART_HEIGHT}
          data={histoData(d3.min)}
          margin={CHART_MARGIN}
          ref={refs[1]}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            label={generateXLabel(
              "Minimum Distances Between Object Centres (μm)"
            )}
          />
          <YAxis width={CHART_Y_WIDTH} dataKey="count" label={ylabel} />
          <Bar
            dataKey="count"
            label={{ position: "top", style: { fill: "black" } }}
          >
            {histoData(d3.min).map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={BAR_COLORS[index % 20]} />
            ))}
          </Bar>
        </BarChart>
      </Chart>
    </>
  );
}

export default Adjacency;
