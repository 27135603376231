import {
  Button,
  Callout,
  Card,
  Checkbox,
  Classes,
  Divider,
  FileInput,
  H2,
  H3,
  H5,
  Icon,
  mergeRefs,
} from "@blueprintjs/core";
import { Classes as Classes2, Popover2, Tooltip2 } from "@blueprintjs/popover2";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Mosaic, MosaicNode, MosaicWindow } from "react-mosaic-component";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { getDemo } from "../../api/demo";
import { uploadWSI } from "../../api/upload";
import MetadataPreview, { MetadataRef } from "../../components/MetadataPreview";
import { ViewID } from "../../constants/types";
import styles from "./style.module.scss";

const Home: React.FC<any> = () => {
  const placeholder = "Choose Digital WSI";
  const helpText = <span>SVS and NDPI images are supported</span>;
  const privateTooltip = (
    <span>
      If set to private, the images you upload will not be publicly visible in
      the list of all results. Each image can still be accessed via its link.
    </span>
  );
  const privateCallout = (
    <span>
      Any images you upload will be available to view by anyone with access to
      this website unless you select the <b>Private</b> option.{" "}
    </span>
  );

  SwiperCore.use([Navigation, Pagination]);

  const initialCurrentNode = "a";

  const [inputFiles, setInputFiles] = useState<FileList | undefined>(undefined);
  const [uploading, setUploading] = useState(false);
  const [svsIds, setSvsIds] = useState<string[]>([]);
  const [currentNode, setCurrentNode] =
    useState<MosaicNode<ViewID>>(initialCurrentNode);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [isPrivate, setIsPrivate] = useState<boolean>(true);
  const metadataPreviewRef = useRef<MetadataRef>(null);

  useEffect(() => {
    if (svsIds.length > 0 && currentNode === initialCurrentNode) {
      setCurrentNode({
        direction: "row",
        first: "a",
        second: "b",
        splitPercentage: 60,
      });
    }
  }, [svsIds, setCurrentNode, currentNode]);

  const upload = async (files: FileList) => {
    try {
      const response = await uploadWSI(files, isPrivate);
      setSvsIds(response.data.ids);
    } catch (error) {
      alert(
        axios.isAxiosError(error) && error.response
          ? error.response.data.detail
          : error
      );
    }
    setUploading(false);
  };

  const launchDemo = () => {
    getDemo().then((response) => {
      window.location.href = `/view-progress/${response.data.id}`;
    }, alert);
  };

  const handleInputChange = (e: any) => {
    if (e.target.files !== undefined && e.target.files.length > 0) {
      setInputFiles(e.target.files);
    }
  };

  const handleUpload = () => {
    if (inputFiles !== undefined) {
      setUploading(true);
      upload(inputFiles).then(() =>
        setFileNames(Array.from(inputFiles).map((file) => file.name))
      );
    }
  };

  const handleSubmit = () => {
    if (svsIds.length > 0) {
      window.location.href = `/view-progress/${svsIds.join(",")}`;
    }
  };

  const ELEMENT_MAP: { [viewID in ViewID]: JSX.Element } = {
    a: (
      <div className={styles.mainContainer}>
        <div className={styles.subContainer}>
          <div className={styles.brandingContainer}>
            <img
              className={styles.logo}
              src={"/assets/microscope.png"}
              alt="Microscope Emoji"
            />
            <H2 className={styles.heading}>KidneyCaliper</H2>
            <span className={styles.description}>
              A deep-learning pipeline for automated digital renal
              histopathology
            </span>
          </div>
          <H3 style={{ marginTop: "1rem" }}>About</H3>
          <p>
            <b>KidneyCaliper</b> is deep-learning pipeline for automatically
            annotating kidney biopsy Whole Slide Images. The first stage of the
            pipeline detects Cortex, Medulla, and Non-Kidney regions. The second
            stage of the pipeline detects features such as Glomeruli, Tubules,
            and Arteries. <b>KidneyCaliper</b> also provides an interactive
            viewer for editing annotations, and generates downloadable
            statistics and graphs.
          </p>
          <p>
            Once processed, statistics across multiple Whole Slide Images can be
            compared using the <b>Cross Comparison</b> tool.
          </p>
          <br />
          <H3>Upload</H3>
          <p>Choose one or more kidney Whole Slide Images to annotate.</p>
          <Card className={styles.uploadCard} elevation={0}>
            <div className={styles.uploadHeader}>
              <div>Upload your Whole Slide Image file</div>
              <Tooltip2 className={styles.tooltipFlex} content={helpText}>
                <Icon size={12} icon="help" />
              </Tooltip2>
            </div>
            <div className={styles.uploadContainer}>
              <FileInput
                inputProps={{ multiple: true }}
                text={
                  inputFiles === undefined
                    ? placeholder
                    : Array.from(inputFiles)
                        .map((file) => file.name)
                        .join(", ")
                }
                onInputChange={handleInputChange}
                fill={true}
              />
            </div>
            {isPrivate || (
              <Callout
                intent="warning"
                style={{ color: "#A66321", marginTop: "0.5rem" }}
              >
                {privateCallout}
              </Callout>
            )}
            <Checkbox
              checked={isPrivate}
              onChange={() => setIsPrivate((isPrivate) => !isPrivate)}
              style={{ margin: "0.5rem" }}
            >
              <Tooltip2
                content={privateTooltip}
                className={Classes.TOOLTIP_INDICATOR}
              >
                Private
              </Tooltip2>
            </Checkbox>
            <div className={styles.buttonContainer}>
              <Button
                disabled={inputFiles ? uploading : true}
                text={uploading ? "Uploading..." : "Upload"}
                className={styles.uploadButton}
                onClick={handleUpload}
                icon="cloud-upload"
                fill={true}
              />
              {svsIds.length > 0 && (
                <>
                  <Divider />
                  <Popover2
                    popoverClassName={Classes2.POPOVER2_CONTENT_SIZING}
                    interactionKind="click"
                    placement="bottom-start"
                    enforceFocus={false}
                    canEscapeKeyClose={true}
                    content={
                      <div key="text">
                        <H5>Confirm Processing Request</H5>
                        <p
                          className={Classes.TEXT_MUTED}
                          style={{ marginBottom: "0.25rem" }}
                        >
                          <b>{inputFiles?.length}</b> Image
                          {inputFiles?.length !== 1 && "s"}
                        </p>
                        <p>
                          Are you sure you'd like to start processing? Do{" "}
                          <b>not</b> submit confidential files without checking
                          the private box as processed files are publicly
                          visible in the results page.
                        </p>
                        {isPrivate && (
                          <p>
                            Since you've marked the submissions as private,
                            their unique IDs will be copied into your{" "}
                            <b>clipboard</b> upon clicking the start button.
                          </p>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 15,
                          }}
                        >
                          <Button
                            className={Classes2.POPOVER2_DISMISS}
                            style={{ marginRight: 10 }}
                          >
                            Cancel
                          </Button>
                          <Button
                            intent="success"
                            className={Classes2.POPOVER2_DISMISS}
                            onClick={() => {
                              navigator.clipboard.writeText(svsIds.join());
                              handleSubmit();
                            }}
                          >
                            Start!
                          </Button>
                        </div>
                      </div>
                    }
                    renderTarget={({ isOpen, ref, ...p }) => (
                      <Button
                        {...p}
                        intent="success"
                        icon="build"
                        disabled={inputFiles ? uploading : true}
                        text={"Start Processing"}
                        className={styles.submit}
                        fill={true}
                        active={isOpen}
                        elementRef={mergeRefs(ref)}
                      />
                    )}
                  />
                </>
              )}
            </div>
          </Card>
          <div className={styles.centerContainer}>
            or
            <Button
              intent="primary"
              text="Try out a demo!"
              onClick={launchDemo}
              icon="presentation"
              className={styles.demoButton}
            />
          </div>
        </div>
      </div>
    ),
    b: (
      <MetadataPreview
        svsIDs={svsIds}
        fileNames={fileNames}
        ref={metadataPreviewRef}
      />
    ),
  };

  const TITLE_MAP: { [viewID in ViewID]: string } = {
    a: "Home",
    b: "Image Metadata",
  };

  const updateSwiper = () => {
    if (metadataPreviewRef.current) metadataPreviewRef.current.updateSwiper();
  };

  return (
    <Mosaic<ViewID>
      renderTile={(id, path) => (
        <MosaicWindow<ViewID>
          title={TITLE_MAP[id]}
          path={path}
          toolbarControls={[]}
          draggable={true}
        >
          {ELEMENT_MAP[id]}
        </MosaicWindow>
      )}
      resize={{ minimumPaneSizePercentage: 20 }}
      value={currentNode}
      onChange={(node) => {
        if (node !== null) setCurrentNode(node);
        updateSwiper();
      }}
    />
  );
};

export default Home;
