import axios from "axios";

function getDemo() {
  return axios.get(`/api/demo`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export { getDemo };
