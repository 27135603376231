import {
  Feature,
  FeatureClass,
  Region,
  RegionClass,
  RegionStatisticName,
  StatisticName,
} from "./types";

export const FeatureKeyToFeatureClass: { [key: string]: FeatureClass } = {
  Tubuli: "Tubulus",
  Vessel_indeterminate: "Vessel Indeterminate",
  Artery: "Artery",
  Glomeruli: "Glomerulus",
};

export const RegionKeyToRegionClass: { [key: string]: RegionClass } = {
  CORTEX: "Cortex",
  MEDULLA: "Medulla",
  NON_KIDNEY: "Non-Kidney",
  TRANSITION: "Transition",
  OTHER: "Other",
};

export const RegionStatisticToString: { [key: string]: RegionStatisticName } = {
  computed_area_micron_sq: "Area of Region (μm²)",
  centroid_x_micron: "Centroid X (μm)",
  centroid_y_micron: "Centroid Y (μm)",
  centroid_x_pixel: "Centroid X (pixels)",
  centroid_y_pixel: "Centroid Y (pixels)",
  perimeter_micron: "Perimeter of Region (μm)",
};

export const StatisticToString: { [key: string]: StatisticName } = {
  computed_area_micron_sq: "Area of Feature (μm²)",
  centroid_x_micron: "Centroid X (μm)",
  centroid_y_micron: "Centroid Y (μm)",
  centroid_x_pixel: "Centroid X (pixels)",
  centroid_y_pixel: "Centroid Y (pixels)",
  perimeter_micron: "Perimeter of Feature (μm)",
  max_inscribed_circle_computed_area_micron_sq:
    "Area of Maximum Inscribed Circle (μm²)",
  max_inscribed_circle_radius_micron: "Radius of Maximum Inscribed Circle (μm)",
  max_inscribed_circle_center_x_micron:
    "Maximum Inscribed Circle Center X (μm)",
  max_inscribed_circle_center_y_micron:
    "Maximum Inscribed Circle Center Y (μm)",
  max_inscribed_circle_radius_pixel:
    "Radius of Maximum Inscribed Circle (pixels)",
  max_inscribed_circle_center_x_pixel:
    "Maximum Inscribed Circle Center X (pixels)",
  max_inscribed_circle_center_y_pixel:
    "Maximum Inscribed Circle Center Y (pixels)",
  max_inscribed_circle_perimeter_micron:
    "Circumference of Maximum Inscribed Circle (μm)",
};

export const StringToStatistic: { [key in StatisticName]: string } = {
  "Area of Feature (μm²)": "computed_area_micron_sq",
  "Centroid X (μm)": "centroid_x_micron",
  "Centroid Y (μm)": "centroid_y_micron",
  "Centroid X (pixels)": "centroid_x_pixel",
  "Centroid Y (pixels)": "centroid_y_pixel",
  "Perimeter of Feature (μm)": "perimeter_micron",
  "Area of Maximum Inscribed Circle (μm²)":
    "max_inscribed_circle_computed_area_micron_sq",
  "Radius of Maximum Inscribed Circle (μm)":
    "max_inscribed_circle_radius_micron",
  "Maximum Inscribed Circle Center X (μm)":
    "max_inscribed_circle_center_x_micron",
  "Maximum Inscribed Circle Center Y (μm)":
    "max_inscribed_circle_center_y_micron",
  "Radius of Maximum Inscribed Circle (pixels)":
    "max_inscribed_circle_radius_pixel",
  "Maximum Inscribed Circle Center X (pixels)":
    "max_inscribed_circle_center_x_pixel",
  "Maximum Inscribed Circle Center Y (pixels)":
    "max_inscribed_circle_center_y_pixel",
  "Circumference of Maximum Inscribed Circle (μm)":
    "max_inscribed_circle_perimeter_micron",
};

export const FeatureClassToColour: { [index in FeatureClass]: string } = {
  Tubulus: "#106BA3",
  "Vessel Indeterminate": "#0D8050",
  Artery: "#D9822B",
  Glomerulus: "#C23030",
};

export const FeatureKeyToColour: { [index in Feature]: string | undefined } = {
  Tubuli: "#C23030",
  Vessel_indeterminate: "#D9822B",
  Artery: "#0D8050",
  Glomeruli: "#106BA3",
  "Kidney region": undefined,
};

export const RegionKeyToColour: { [index in Region]: string } = {
  CORTEX: "#DB2C6F",
  MEDULLA: "#00B3A4",
  TRANSITION: "#7157D9",
  NON_KIDNEY: "#29A634",
};
