import {
  FeatureKeyToFeatureClass,
  RegionKeyToRegionClass,
} from "../constants/map";
import { FeatureStatistics } from "../constants/types";
import { parseCSV } from "./csv";

export async function csvToFeatureStatistics(
  svsId: string
): Promise<FeatureStatistics[]> {
  const csv: any = await parseCSV(`/api/feature-statistics/${svsId}`);
  return csv.data
    .filter((row: any) => {
      return (
        Object.keys(FeatureKeyToFeatureClass).includes(row.classification) &&
        Object.keys(RegionKeyToRegionClass).includes(row.region)
      );
    })
    .map((row: any) => {
      const { classification, region, ...rest } = row;
      const numericRest = Object.fromEntries(
        Object.entries(rest).map(([k, v]) => [k, Number(v)])
      );
      return {
        classification: FeatureKeyToFeatureClass[classification],
        region: RegionKeyToRegionClass[region],
        ...numericRest,
      };
    });
}
