import { Card, Checkbox, Divider } from "@blueprintjs/core";
import {
  DateRange,
  DateRangeInput,
  TimePrecision,
} from "@blueprintjs/datetime";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import React from "react";
import { Run } from "../../constants/types";
import FileRow from "../FileRow";
import styles from "./style.module.scss";

type Props = {
  slides: Map<string, Run>;
  onChange: (svsId: string, checked: boolean) => any;
};

type State = {
  selected: Set<string>;
  filtered: Set<string>;
};

class FilePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selected: new Set(),
      filtered: new Set(props.slides.keys()),
    };
    this.onAllCheckChange = this.onAllCheckChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.onDateRangeChange = this.onDateRangeChange.bind(this);
  }

  onCheckChange(svsId: string): React.ChangeEventHandler<HTMLInputElement> {
    return (event) => {
      const setCopy = this.state.selected;
      if (event.target.checked) {
        setCopy.add(svsId);
      } else {
        setCopy.delete(svsId);
      }
      this.setState({ selected: setCopy });
      return this.props.onChange(svsId, event.target.checked);
    };
  }

  onAllCheckChange(event: React.ChangeEvent<HTMLInputElement>) {
    let newSet: Set<string>;
    if (event.target.checked) {
      newSet = new Set(this.props.slides.keys());
      const toBeChecked = [...this.state.filtered].filter(
        (svsId) => !this.state.selected.has(svsId)
      );
      toBeChecked.forEach((svsId) => this.props.onChange(svsId, true));
    } else {
      newSet = new Set();
      const toBeUnchecked = this.state.filtered;
      toBeUnchecked.forEach((svsId) => this.props.onChange(svsId, false));
    }
    this.setState({ selected: newSet });
  }

  MIN_DATE = new Date(-8640000000000000);
  MAX_DATE = new Date(8640000000000000);

  onDateRangeChange([start, end]: DateRange) {
    const newFiltered: Set<string> = new Set();
    for (const [id, run] of this.props.slides.entries()) {
      if (
        (start ?? this.MIN_DATE) <= run.time_created &&
        run.time_created <= (end ?? this.MAX_DATE)
      ) {
        newFiltered.add(id);
      }
    }
    this.setState({ filtered: newFiltered });
  }

  render() {
    const cards = [];

    for (const svsId of this.state.filtered.keys()) {
      cards.push(
        <FileRow
          run={this.props.slides.get(svsId) as Run}
          onChange={this.onCheckChange(svsId)}
          checked={this.state.selected.has(svsId)}
          variant="picker"
        />
      );
    }

    const partiallyChecked =
      this.state.selected.size > 0 &&
      this.state.selected.size !== this.props.slides.size;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <Card className={styles.toolbar}>
            <Checkbox
              checked={this.state.selected.size === this.props.slides.size}
              indeterminate={partiallyChecked}
              onChange={this.onAllCheckChange}
              large
              className={styles.checkbox}
            />
            <DateRangeInput
              formatDate={(date) => date.toLocaleString()}
              parseDate={(str) => new Date(str)}
              onChange={this.onDateRangeChange}
              highlightCurrentDay={true}
              allowSingleDayRange={true}
              selectAllOnFocus={true}
              timePrecision={TimePrecision.MINUTE}
            />
          </Card>
          <Divider
            style={{
              marginBottom: "0rem",
              marginTop: "1rem",
              width: "100%",
              marginRight: "0",
              marginLeft: "0",
            }}
          />
        </div>
        <div className={styles.cards}>{cards}</div>
      </div>
    );
  }
}

export default FilePicker;
