import * as d3 from "d3-array";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { Cell, Pie, PieChart } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { CHART_HEIGHT, CHART_WIDTH, PIE_COLORS } from "../../constants/chart";
import { FeatureStatistics } from "../../constants/types";
import Chart from "../Chart";
import PieLabel from "../PieLabel";

function FeatureCount(props: { features: FeatureStatistics[] }) {
  const countMap = d3.rollup(
    props.features,
    (group) => group.length,
    (feature) => feature.classification
  );
  const counts = Array.from(countMap, ([name, count]) => ({ name, count }));
  const [getPng, { ref }] = useCurrentPng();
  const handle = useFullScreenHandle();

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    /* Download with Filesaver */
    if (png !== undefined) {
      FileSaver.saveAs(png, "feature-count.png");
    }
  }, [getPng]);

  return (
    <Chart
      fullscreenHandle={handle}
      handleDownload={handleDownload}
      height={CHART_HEIGHT}
    >
      <PieChart width={CHART_WIDTH} height={CHART_HEIGHT} ref={ref}>
        <Pie
          data={counts}
          isAnimationActive={false}
          innerRadius="60%"
          paddingAngle={2}
          fill="#8884d8"
          dataKey="count"
          label={PieLabel}
        >
          {counts.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={PIE_COLORS[index % PIE_COLORS.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </Chart>
  );
}

export default FeatureCount;
