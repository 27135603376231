import {
  AnchorButton,
  Callout,
  Dialog,
  H3,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createDefaultToolbarButton,
  Mosaic,
  MosaicNode,
  MosaicWindow,
  RemoveButton,
} from "react-mosaic-component";
import { useParams } from "react-router-dom";
import "swiper/swiper.min.css";
import { getPastRuns } from "../../api/history";
import { requestProcessing } from "../../api/upload";
import FileRow from "../../components/FileRow";
import MetadataPreview, { MetadataRef } from "../../components/MetadataPreview";
import PrivateField from "../../components/PrivateField";
import { HistoryHelp, MetadataHelp } from "../../constants/help";
import { RunRowInfo, ViewID } from "../../constants/types";
import styles from "./style.module.scss";

const History: React.FC<any> = () => {
  const { svsIds: svsIDs } = useParams<{ svsIds?: string }>();
  const svsIDList = useMemo(() => svsIDs?.split(",") ?? [], [svsIDs]);
  const progressPageMode = svsIDs !== undefined;
  const [pastRuns, setPastRuns] = useState<Map<string, RunRowInfo>>(new Map());
  const [rows, setRows] = useState<JSX.Element>();
  const [loading, setLoading] = useState<boolean>(true);
  const [identifier, setIdentifier] = useState<string | undefined>(undefined);
  const ws = useRef<WebSocket | null>(null);

  /* Preview Pane */
  const [previewID, setPreviewID] = useState<string>();
  const metadataPreviewRef = useRef<MetadataRef>(null);

  /* Mosaic Window Management */
  const initialCurrentNode = "a";
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [metadataHelpOpen, setMetadataHelpOpen] = useState<boolean>(false);
  const [currentNode, setCurrentNode] =
    useState<MosaicNode<ViewID>>(initialCurrentNode);
  const helpButtonMap: { [viewID in ViewID]: JSX.Element } = {
    a: createDefaultToolbarButton(
      "Help",
      "bp3-icon-help",
      () => {
        setHelpOpen(!helpOpen);
      },
      ""
    ),
    b: createDefaultToolbarButton(
      "Help",
      "bp3-icon-help",
      () => {
        setMetadataHelpOpen(!metadataHelpOpen);
      },
      ""
    ),
  };

  const redirectToResult = (id: string) => {
    window.location.href = `/result/${id}`;
  };

  const handleWebSocketMessage = useCallback(
    (msg) => {
      setPastRuns((pastRuns) => {
        const msgJson: any[] = JSON.parse(msg.data);
        const newPastRuns = new Map(pastRuns);

        msgJson.forEach((msgJsonElem) => {
          const updateCurrRun: Partial<RunRowInfo> = {};

          if (msgJsonElem.hasOwnProperty("queue_position")) {
            /*  Queue position update message */
            updateCurrRun.queue_position = msgJsonElem.queue_position;
          } else if (msgJsonElem.hasOwnProperty("progress")) {
            /* Progress bar update message */
            updateCurrRun.progress = msgJsonElem.progress;
            updateCurrRun.queue_position = undefined;
          } else if (msgJsonElem.pipeline_finished === true) {
            /* processing done message */
            updateCurrRun.progress = 1;
            updateCurrRun.queue_position = undefined;
            updateCurrRun.processing_status = "COMPLETED";
            if (svsIDList.length === 1 && progressPageMode) {
              redirectToResult(svsIDList[0]);
            }
          }

          /* Update the data in pastRuns witch changes from updatedCurrRun */
          const currRun = newPastRuns.get(msgJsonElem.id);
          if (currRun !== undefined) {
            newPastRuns.set(msgJsonElem.id, { ...currRun, ...updateCurrRun });
          } else {
            console.warn(
              `Received websocket message for unknown run: ${msgJsonElem.id}`
            );
          }
        });

        return newPastRuns;
      });
    },
    [progressPageMode, svsIDList]
  );

  const openMetadataPreview = useCallback(
    (svsID: string) => {
      setPreviewID(svsID);
      if (currentNode === initialCurrentNode) {
        setCurrentNode({
          direction: "row",
          first: "a",
          second: "b",
          splitPercentage: 60,
        });
      }
    },
    [currentNode]
  );

  useEffect(() => {
    const setup = () => {
      setLoading(false);

      getPastRuns(svsIDList).then((pastRunsResp) => {
        setPastRuns((pastRuns) => {
          const pastRunsRespParsed: RunRowInfo[] = pastRunsResp.map((run) => ({
            ...run,
            queue_position: undefined,
          }));
          const newPastRuns = new Map(
            pastRunsRespParsed.map((run) => [
              run.id,
              /* Do not overwrite existing data from pastRuns */
              { ...run, ...pastRuns.get(run.id) },
            ])
          );

          const notYetCompletedIds = Array.from(newPastRuns)
            .filter(([_, run]) => run.processing_status !== "COMPLETED")
            .map(([id, _]) => id);

          if (
            svsIDList.length === 1 &&
            notYetCompletedIds.length === 0 &&
            progressPageMode
          ) {
            redirectToResult(svsIDList[0]);
          }

          if (notYetCompletedIds.length !== 0) {
            const webSocketUrl =
              `${window.location.protocol === "https:" ? "wss" : "ws"}://${
                window.location.host
              }/api/progress-ws/?` +
              notYetCompletedIds.map((svsId) => `svs_ids=${svsId}`).join("&");
            ws.current = new WebSocket(webSocketUrl);
            ws.current.onmessage = handleWebSocketMessage;
          }
          return newPastRuns;
        });
      });
    };

    /* Request processing for all ids only if an ID list has been explicitly
     * provided */
    if (progressPageMode) {
      Promise.all(svsIDList.map(requestProcessing)).then(setup).catch(alert);
    } else {
      setup();
    }
  }, [handleWebSocketMessage, progressPageMode, svsIDList]);

  const handleInputChange = (e: any) => {
    if (e.target !== undefined) {
      setIdentifier((e.target as HTMLInputElement).value);
    }
  };

  const updateSwiper = () => {
    if (metadataPreviewRef.current) {
      metadataPreviewRef.current.updateSwiper();
    }
  };

  useEffect(() => {
    const runs = Array.from(pastRuns);
    const inProgress = runs
      .filter(([_, run]) => run.processing_status !== "COMPLETED")
      .sort(
        ([id1, firstRun], [id2, secondRun]) =>
          (firstRun.queue_position ?? -1) - (secondRun.queue_position ?? -1)
      );
    const complete = runs.filter(
      ([_, run]) => run.processing_status === "COMPLETED"
    );
    setRows(
      <div>
        {inProgress.length > 0 && (
          <div style={{ marginBottom: "1rem" }}>
            <H3 style={{ paddingTop: 30 }}>In Progress</H3>
            <span>
              Your submissions are currently being processed in the pipeline.
              <Callout intent="warning" style={{ marginTop: "0.5rem" }}>
                Please note that some submissions may take more time to process
                than others; if you've made your submission private, please keep
                a note of the unique identifier to manually access the results
                page!
              </Callout>
            </span>
          </div>
        )}
        {inProgress.map(([_, run]) => {
          return (
            <React.Fragment key={run.id}>
              <FileRow
                variant="standard"
                run={{
                  id: run.id,
                  progress: run.progress,
                  processing_status: run.processing_status,
                  time_created: run.time_created,
                  filename: run.filename,
                  original_id: run.original_id,
                }}
                queuePosition={run.queue_position}
                resultButtonHidden={pastRuns.size === 1 && progressPageMode}
                openMetadata={() => openMetadataPreview(run.original_id)}
              />
            </React.Fragment>
          );
        })}
        {complete.length > 0 && (
          <div style={{ marginBottom: "1rem" }}>
            <H3 style={{ paddingTop: 30 }}>Complete</H3>
            <div style={{ marginBottom: "1rem" }}>
              The list of submissions below have been processed by the deep
              learning pipeline. Please click on <b>See Result</b> to be
              redirected to the results page or right click for additional
              options (download/share).
            </div>
            <PrivateField
              identifier={identifier}
              handleInputChange={handleInputChange}
            />
          </div>
        )}
        {complete.map(([_, run]) => {
          return (
            <React.Fragment key={run.id}>
              <FileRow
                variant="standard"
                run={{
                  id: run.id,
                  progress: run.progress,
                  processing_status: run.processing_status,
                  time_created: run.time_created,
                  filename: run.filename,
                  original_id: run.original_id,
                }}
                queuePosition={run.queue_position}
                resultButtonHidden={pastRuns.size === 1 && progressPageMode}
                openMetadata={() => openMetadataPreview(run.original_id)}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  }, [pastRuns, progressPageMode, openMetadataPreview, identifier]);

  const ELEMENT_MAP: { [viewId in ViewID]: JSX.Element } = {
    a: (
      <div className={styles.container}>
        <div className={styles.history}>
          {loading ? (
            <Spinner />
          ) : pastRuns.size > 0 ? (
            rows
          ) : (
            <>
              <NonIdealState
                icon="history"
                title="Empty History"
                description="There are no processed images to show at this moment."
                className={styles.nonIdeal}
                action={
                  <AnchorButton
                    text="Upload Slides"
                    icon="upload"
                    intent="primary"
                    onClick={() => (window.location.href = "/")}
                  />
                }
              />
              <div className={styles.center}>or</div>
              <PrivateField
                identifier={identifier}
                handleInputChange={handleInputChange}
              />
            </>
          )}
        </div>
      </div>
    ),
    b: (
      <MetadataPreview
        svsIDs={previewID !== undefined ? [previewID] : []}
        fileNames={previewID !== undefined ? [previewID] : []}
        ref={metadataPreviewRef}
      />
    ),
  };

  return (
    <>
      <Mosaic<ViewID>
        value={currentNode}
        onChange={(node) => {
          if (node !== null) setCurrentNode(node);
          updateSwiper();
        }}
        renderTile={(id, path) => (
          <MosaicWindow<ViewID>
            title={
              (id === "b" && "Image Metadata") ||
              (id === "a" && progressPageMode ? "Progress" : "History")
            }
            path={path}
            toolbarControls={[
              helpButtonMap[id],
              id === "b" && <RemoveButton />,
            ]}
            draggable={false}
          >
            {ELEMENT_MAP[id]}
          </MosaicWindow>
        )}
      />
      <Dialog
        isOpen={helpOpen}
        onClose={() => setHelpOpen(!helpOpen)}
        title={"History Help"}
        icon={"help"}
      >
        <HistoryHelp />
      </Dialog>
      <Dialog
        isOpen={metadataHelpOpen}
        onClose={() => setMetadataHelpOpen(!metadataHelpOpen)}
        title={"Image Metadata Help"}
        icon={"help"}
      >
        <MetadataHelp />
      </Dialog>
    </>
  );
};

export default History;
