import * as d3 from "d3-array";
import FileSaver from "file-saver";
import { useCallback } from "react";
import { useFullScreenHandle } from "react-full-screen";
import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";
import { UseCurrentPng, useCurrentPng } from "recharts-to-png";
import {
  BAR_COLORS,
  CHART_HEIGHT,
  CHART_MARGIN,
  CHART_WIDTH,
  CHART_Y_WIDTH,
} from "../../constants/chart";
import { FeatureStatistics } from "../../constants/types";
import { generateXLabel, generateYLabel } from "../../utils/chart";
import Chart from "../Chart";

function AreaDistribution(props: { features: FeatureStatistics[] }) {
  const truncate = (val?: number) => val?.toFixed(2).replace(/\.0+$/, "");

  const histoData = (accessor: (f: FeatureStatistics) => number) => {
    const bins = d3.bin<FeatureStatistics, number>().value(accessor)(
      props.features
    );

    return bins.map((bin) => ({
      range: `${truncate(bin.x0)}-${truncate(bin.x1)}`,
      count: bin.length,
    }));
  };

  const ylabel = generateYLabel("Predicted Count");

  const useMultiplePngs = (currentPngs: UseCurrentPng[]) => {
    const getPngs: (() => Promise<string | undefined>)[] = [];
    const refs: React.MutableRefObject<any>[] = [];
    currentPngs.forEach(function ([getPng, { ref }]) {
      getPngs.push(getPng);
      refs.push(ref);
    });
    return { getPngs, refs };
  };

  const currentPngs = [useCurrentPng(), useCurrentPng()];
  const { getPngs, refs } = useMultiplePngs(currentPngs);

  const handles = [useFullScreenHandle(), useFullScreenHandle()];

  const handleDownload = useCallback(
    async (index: number) => {
      const png = await getPngs[index]();

      /* Download with FileSaver */
      if (png !== undefined) {
        FileSaver.saveAs(png, "scatter-plot.png");
      }
    },
    [getPngs]
  );

  return (
    <>
      {/* 1. Predicted Count vs. Size of Feature */}
      <Chart
        fullscreenHandle={handles[0]}
        handleDownload={() => handleDownload(0)}
        height={CHART_HEIGHT}
      >
        <BarChart
          width={CHART_WIDTH}
          height={CHART_HEIGHT}
          data={histoData((feature) => feature.computed_area_micron_sq)}
          margin={CHART_MARGIN}
          ref={refs[0]}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            label={generateXLabel("Size of Feature (μm²)")}
          />
          <YAxis width={CHART_Y_WIDTH} dataKey="count" label={ylabel} />
          <Bar
            dataKey="count"
            label={{ position: "top", style: { fill: "black" } }}
          >
            {histoData((feature) => feature.computed_area_micron_sq).map(
              (_entry, index) => (
                <Cell key={`cell-${index}`} fill={BAR_COLORS[index % 20]} />
              )
            )}
          </Bar>
        </BarChart>
      </Chart>

      {/* 2. Predicted Count vs. Area of maximum inscribed circle */}
      <Chart
        fullscreenHandle={handles[1]}
        handleDownload={() => handleDownload(1)}
        height={CHART_HEIGHT}
      >
        <BarChart
          width={CHART_WIDTH}
          height={CHART_HEIGHT}
          data={histoData(
            (feature) => feature.max_inscribed_circle_computed_area_micron_sq
          )}
          margin={CHART_MARGIN}
          ref={refs[1]}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            label={generateXLabel("Area of Maximum Inscribed Circle (μm²)")}
          />
          <YAxis width={CHART_Y_WIDTH} dataKey="count" label={ylabel} />
          <Bar
            dataKey="count"
            label={{ position: "top", style: { fill: "black" } }}
          >
            {histoData(
              (feature) => feature.max_inscribed_circle_computed_area_micron_sq
            ).map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={BAR_COLORS[index % 20]} />
            ))}
          </Bar>
        </BarChart>
      </Chart>
    </>
  );
}

export default AreaDistribution;
