import { Button, Callout, Classes, Tag } from "@blueprintjs/core";
import { TopToaster } from "../../constants/toaster";

function Share(props: { svsID: string }) {
  const base = window.location.origin;
  const copyLink = async () => {
    await navigator.clipboard.writeText(`${base}/result/${props.svsID}`);
    TopToaster.show({
      message: "Link copied to clipboard!",
      icon: "clipboard",
      intent: "success",
    });
  };

  return (
    <div className={Classes.DIALOG_BODY}>
      Share <b>{props.svsID}</b> with your colleagues! Click on the button below
      to copy the following share link to your clipboard.
      <Callout intent="warning" style={{ marginTop: "0.5rem" }}>
        Please be careful when sharing private files!
      </Callout>
      <Tag
        large={true}
        minimal={true}
        style={{ marginTop: "0.5rem", marginBottom: "1rem", width: "100%" }}
      >
        {base}/result/{props.svsID}
      </Tag>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button icon="clipboard" text="Copy link" onClick={copyLink} />
      </div>
    </div>
  );
}

export default Share;
