import styles from "./style.module.scss";

function ScatterTooltip(props: any) {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className={styles.tooltip}>
        <div style={{ marginBottom: "0.375rem" }}>
          <span>{props.payload[0].name.toUpperCase()}: </span>
          <span style={{ fontWeight: 600 }}>
            {props.payload[0].value.toFixed(2)}
          </span>
        </div>
        <div>
          <span>{props.payload[1].name.toUpperCase()}: </span>
          <span style={{ fontWeight: 600 }}>
            {props.payload[1].value.toFixed(2)}
          </span>
        </div>
      </div>
    );
  }

  return null;
}

export default ScatterTooltip;
