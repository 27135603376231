import { useEffect } from "react";
import { isChrome } from "react-device-detect";
import { Route, Switch } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import { BottomToaster } from "../constants/toaster";
import "./App.scss";
import CrossComparison from "./CrossComparison";
import PastRunsPage from "./History";
import Home from "./Home";
import PageNotFound from "./PageNotFound";
import Results from "./Results";

const App: React.FC<any> = () => {
  useEffect(() => {
    if (!isChrome) {
      BottomToaster.show({
        message: "Unsupported Browser! Chrome recommended.",
        icon: "warning-sign",
        intent: "warning",
      });
    }
  }, []);

  return (
    <>
      <NavigationBar />
      <Switch>
        <Route path="/result/:svsId" component={Results} />
        <Route path="/view-progress/:svsIds" component={PastRunsPage} />
        <Route path="/past-runs/" component={PastRunsPage} />
        <Route path="/cross-comparison" component={CrossComparison} />
        <Route exact path="/" component={Home} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default App;
