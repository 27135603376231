import { Callout, Classes, Icon } from "@blueprintjs/core";

export const CCStatisticsHelp = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      <b>Statistics</b> allows you to visualise correlations between multiple
      processed digital whole slide images using the generated measurements of
      the features and regions. Use the dropdowns to select the measurements to
      plot along the plot axes. For more options (download/fullscreen), please
      right click the graph.
    </p>
  </div>
);

export const FilePickerHelp = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      <b>File Select</b> lists the processed files made publicly visible. This
      list includes modified as well as original files; modified files are
      tagged with <b>(Edited)</b> next to the timestamp. Use the date range
      selector to filter the list!
    </p>
  </div>
);

export const HistoryHelp = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      <b>Progress/History</b> allows you to track the progress and completion of
      digital slide images submitted to be processed in the pipeline. Click on
      the <b>See result</b> button for completed results to be redirected to the
      results page or right click a row for options to export annoations and
      statistics, get a shareable link, or preview the metadata of your
      submission.
    </p>
    <Callout style={{ marginTop: "0.5rem" }} intent="warning">
      Please note that private submissions will not be visible in the list of
      completed submissions; please make sure to take a note of the unique
      identifier for private submissions.
    </Callout>
  </div>
);

export const MetadataHelp = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      <b>Image Metadata</b> allows you to view a thumnail of the submitted
      digital whole slide image along with a table of the accompanying metadata
      generated by the image scanner.
    </p>
  </div>
);

export const ViewerHelp = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      The <b>Whole Slide Image Viewer</b> allows you to view your uploaded
      image, and view, edit, and delete the annotations generated for them.
    </p>
    <p>
      The <b>Polygon</b>
      <Icon className={"helpIcon"} size={14} icon="polygon-filter" /> and{" "}
      <b>Freehand</b>
      <Icon className={"helpIcon"} size={14} icon="draw" /> drawing tools allow
      you to draw new annotations on the image. Double-click to finish drawing
      the annotation, then enter feature and region tags in the pop-up, and
      press Ok to create the annotation. Once created, measurements will be
      automatically added to the new annotation. To save your changes and update
      the graphs in the <b>Statistics</b> panel, press the <b>Commit Changes</b>
      <Icon className={"helpIcon"} size={14} icon="floppy-disk" /> button.
    </p>
    <p>
      You can click annotations to view their generated statistics, and can also
      edit tags/statistics and delete annotations from the pop-up.
    </p>
    <hr style={{ margin: "1.5rem 0rem", border: "0.0625rem solid #CED9E0" }} />
    <p>
      <Icon className={"listIcon"} size={14} icon="polygon-filter" /> Draws a
      new polygon annotation
    </p>
    <p>
      <Icon className={"listIcon"} size={14} icon="draw" /> Draws a new freehand
      annotation
    </p>
    <p>
      <Icon className={"listIcon"} size={14} icon="zoom-in" /> Zoom in
    </p>
    <p>
      <Icon className={"listIcon"} size={14} icon="zoom-out" /> Zoom out
    </p>
    <p>
      <Icon className={"listIcon"} size={14} icon="zoom-to-fit" /> Adjust zoom
      to fill screen
    </p>
    <p>
      <Icon className={"listIcon"} size={14} icon="fullscreen" /> Toggle full
      page
    </p>
    <p>
      <Icon className={"listIcon"} size={14} icon="filter" /> Filters
      annotations by feature, region, and if it's an inscribed circle
    </p>
    <p style={{ marginBottom: 0 }}>
      <Icon className={"listIcon"} size={14} icon="floppy-disk" /> Saves changes
      and updates statistics by creating a new file - previous versions of the
      annotations can be accessed at the previous links
    </p>
  </div>
);

export const RStatisticsHelp = () => (
  <div className={Classes.DIALOG_BODY}>
    <p>
      <b>Results</b> allows you to visualise generated statistics for your
      uploaded whole slide image. Right-click on the graphs below to download a
      copy, or view the graph in full-screen for a higher resolution screenshot.
    </p>
    <hr style={{ margin: "1.5rem 0rem", border: "0.0625rem solid #CED9E0" }} />
    <p>
      <Icon className={"listIcon"} size={14} icon="cloud-download" /> Export
      annotations/statistics for your whole slide image. You can download the
      annotations either as <b>GeoJSON</b> for viewing in <b>QuPath</b> or as{" "}
      <b>W3C Web Annotations</b>, and can download <b>Feature</b> and{" "}
      <b>Region</b> statistics separately or together.
    </p>
    <p>
      <Icon className={"listIcon"} size={14} icon="share" /> Copy shareable link
      to results page. The results page is only visible to users with this link.
    </p>
  </div>
);
