import { Button, HTMLTable, Tag } from "@blueprintjs/core";
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { metadataWSI } from "../../api/metadata";
import styles from "./style.module.scss";

/* Call swiper update from outside to enable swiper's responsive layout */
export interface MetadataRef {
  updateSwiper: () => void;
}

const MetadataPreview = forwardRef(
  (props: { svsIDs: string[]; fileNames: string[] }, ref: Ref<MetadataRef>) => {
    useImperativeHandle(ref, () => ({
      updateSwiper: () => {
        if (swiper !== undefined) swiper.update();
      },
    }));

    const [swiper, setSwiper] = useState<SwiperCore | undefined>(undefined);
    const [metadataTables, setMetadataTables] = useState<any[]>([]);

    useEffect(() => {
      if (props.svsIDs.length === 0) return;

      /* Fetch and store the metadata */
      Promise.all(props.svsIDs.map(metadataWSI))
        .then((responses) => {
          setMetadataTables(
            responses.map((response) =>
              unpackMetadata(response.data, [
                "openslide.comment",
                "tiff.ImageDescription",
              ])
            )
          );
        })
        .catch(alert);
    }, [props.svsIDs]);

    /* Removes and unpacks keys that hold nested metadata separated by | and =
     * For example, "openslide.comment": "AppMag = 40|StripeWidth = 1840"
     * becomes "openslide.AppMag": "40", "openslide.StripeWidth": "1840" */
    const unpackMetadata = (newMetadata: any, keys: string[]) => {
      keys.forEach((key: string) => {
        const keyType = key.split(".")[0];
        if (key in newMetadata) {
          const fields = newMetadata[key].split("|");
          fields
            .map((field: string) => field.split(" = "))
            .forEach((headerValue: string[]) => {
              if (headerValue.length === 2) {
                const header = headerValue[0];
                const value = headerValue[1];
                newMetadata[keyType + "." + header] = value;
              }
            });
          delete newMetadata[key];
        }
      });

      return newMetadata;
    };

    let formattedMetadataTables: string[][][] = [];

    if (metadataTables !== []) {
      formattedMetadataTables = metadataTables.map(() => []);
      const seenMetadata: Set<string>[] = metadataTables.map(
        () => new Set<string>()
      );

      metadataTables.forEach((metadata: any, index: number) => {
        Object.keys(metadata).forEach((key: string) => {
          const dotIndex = key.indexOf(".");
          const header = key.substring(dotIndex + 1);
          const value: string = metadata[key];
          if (!seenMetadata[index].has(header + value)) {
            formattedMetadataTables[index].push([header, value]);
            /* Adding a string to simplify logic away from sets of arrays (that 
              compare by reference) */
            seenMetadata[index].add(header + value);
          }
        });
      });
    }

    return (
      <Swiper
        className={styles.container}
        onSwiper={(swiper) => setSwiper(swiper)}
        onInit={(swiper) => {
          if (props.svsIDs === undefined || props.svsIDs.length <= 1) {
            swiper.disable();
          }
        }}
      >
        {props.svsIDs.map((svsId: string, index: number) => (
          <SwiperSlide className={styles.slide}>
            <div className={styles.toolbar}>
              <Button
                disabled={index === 0}
                icon="chevron-left"
                onClick={() => {
                  swiper?.slidePrev();
                }}
              />
              <Tag large={true} minimal={true}>
                {props.fileNames[index]}
              </Tag>
              <Button
                disabled={index === props.svsIDs.length - 1}
                icon="chevron-right"
                onClick={() => {
                  swiper?.slideNext();
                }}
              />
            </div>
            <div className={styles.thumbnail}>
              <img
                src={`/api/thumbnail/${svsId}`}
                alt="Thumbnail of uploaded digital WSI"
              />
            </div>
            <div className={styles.table}>
              {formattedMetadataTables[index]?.length === 0 ? undefined : (
                <HTMLTable
                  bordered={true}
                  condensed={true}
                  interactive={true}
                  striped={true}
                >
                  <thead>
                    <tr>
                      <th>Metadata</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formattedMetadataTables[index]?.map((values: string[]) => (
                      <tr>
                        {values.map((value) => (
                          <td>{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }
);

export default MetadataPreview;
