import {
  AnchorButton,
  Dialog,
  Intent,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import React from "react";
import {
  createDefaultToolbarButton,
  ExpandButton,
  Mosaic,
  MosaicWindow,
} from "react-mosaic-component";
import { getPastRuns } from "../../api/history";
import CCStatistics from "../../components/CCStatistics";
import FilePicker from "../../components/FilePicker";
import { CCStatisticsHelp, FilePickerHelp } from "../../constants/help";
import {
  MapTree,
  Run,
  ViewID,
  WholeSlideStatisticPath,
  WholeSlideStats,
} from "../../constants/types";
import { csvToWholeSlideStatistic } from "../../utils/crossComparison";

type State = {
  slides: Map<string, Run>;
  stats: Map<string, WholeSlideStats>;
  selectedStats: Map<string, WholeSlideStats>;
  pathTree: MapTree<WholeSlideStatisticPath>;
  loading: boolean;
  isStatisticsHelpOpen: boolean;
  isFileHelpOpen: boolean;
};

class CrossComparisonComponent extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      slides: new Map(),
      stats: new Map(),
      selectedStats: new Map(),
      pathTree: new Map(),
      loading: true,
      isStatisticsHelpOpen: false,
      isFileHelpOpen: false,
    };

    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.statisticsHelpToggle = this.statisticsHelpToggle.bind(this);
    this.fileHelpToggle = this.fileHelpToggle.bind(this);
  }

  componentDidMount() {
    getPastRuns()
      .then((pastRuns) => {
        const runs = pastRuns.filter(
          (run) => run.processing_status === "COMPLETED"
        );
        return runs;
      })
      .then(async (runs) => {
        const slides = new Map();
        const stats = new Map();
        const pathTrees: MapTree<WholeSlideStatisticPath>[] = [];
        for (const run of runs) {
          slides.set(run.id, run);
          const [data, paths] = await csvToWholeSlideStatistic(run.id);
          stats.set(run.id, data);
          pathTrees.push(paths);
        }
        // TODO: now we just pick the first one. But we need a merge function.
        this.setState({
          slides: slides,
          stats: stats,
          pathTree: pathTrees[0],
          loading: false,
        });
      });
  }

  onSelectionChange(svsId: string, checked: boolean) {
    const selectedCopy = this.state.selectedStats;
    if (checked) {
      selectedCopy.set(svsId, this.state.stats.get(svsId) as WholeSlideStats);
    } else {
      selectedCopy.delete(svsId);
    }
    this.setState({ selectedStats: selectedCopy });
  }

  statisticsHelpToggle() {
    this.setState({ isStatisticsHelpOpen: !this.state.isStatisticsHelpOpen });
  }

  fileHelpToggle() {
    this.setState({ isFileHelpOpen: !this.state.isFileHelpOpen });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    } else if (this.state.slides.size === 0) {
      // If things have loaded but we have no slides
      return (
        <NonIdealState
          icon="error"
          title="No data available"
          description="You have not uploaded any scans for analysis"
          action={
            <AnchorButton
              icon="upload"
              text="Upload Slides"
              intent={Intent.PRIMARY}
              onClick={() => (window.location.href = "/")}
            />
          }
        />
      );
    }

    const ELEMENT_MAP: { [viewID in ViewID]: JSX.Element } = {
      a: (
        <CCStatistics
          slideStats={Array.from(this.state.selectedStats.values())}
          pathTree={this.state.pathTree}
        />
      ),
      b: (
        <FilePicker
          slides={this.state.slides}
          onChange={this.onSelectionChange}
        />
      ),
    };

    const TITLE_MAP: { [viewID in ViewID]: string } = {
      a: "Statistics",
      b: "File Select",
    };

    const helpButtonMap: { [viewID in ViewID]: JSX.Element } = {
      a: createDefaultToolbarButton(
        "Help",
        "bp3-icon-help",
        this.statisticsHelpToggle,
        ""
      ),
      b: createDefaultToolbarButton(
        "Help",
        "bp3-icon-help",
        this.fileHelpToggle,
        ""
      ),
    };

    return (
      <>
        <Mosaic<ViewID>
          renderTile={(id, path) => (
            <MosaicWindow<ViewID>
              title={TITLE_MAP[id]}
              path={path}
              toolbarControls={[helpButtonMap[id], <ExpandButton />]}
              draggable={true}
            >
              {ELEMENT_MAP[id]}
            </MosaicWindow>
          )}
          resize={{ minimumPaneSizePercentage: 30 }}
          initialValue={{
            direction: "row",
            first: "a",
            second: "b",
            splitPercentage: 60,
          }}
        />
        <Dialog
          isOpen={this.state.isStatisticsHelpOpen}
          onClose={this.statisticsHelpToggle}
          title={"Statistics Help"}
          icon={"help"}
        >
          <CCStatisticsHelp />
        </Dialog>
        <Dialog
          isOpen={this.state.isFileHelpOpen}
          onClose={this.fileHelpToggle}
          title={"File Select Help"}
          icon={"help"}
        >
          <FilePickerHelp />
        </Dialog>
      </>
    );
  }
}

const CrossComparison: React.FC<any> = () => {
  return <CrossComparisonComponent />;
};

export default CrossComparison;
