import { Button, ButtonGroup, Dialog, Menu, Tag } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React, { Component } from "react";
import {
  createDefaultToolbarButton,
  ExpandButton,
  Mosaic,
  MosaicWindow,
} from "react-mosaic-component";
import "react-mosaic-component/react-mosaic-component.css";
import { useParams } from "react-router-dom";
import { getPastRuns } from "../../api/history";
import ExportMenu from "../../components/ExportMenu";
import ImageViewer from "../../components/ImageViewer";
import RStatistics from "../../components/RStatistics";
import Share from "../../components/Share";
import { RStatisticsHelp, ViewerHelp } from "../../constants/help";
import { FeatureStatistics, ViewID } from "../../constants/types";
import { csvToFeatureStatistics } from "../../utils/result";
import styles from "./style.module.scss";

type Props = {
  svsId: string;
};

type State = {
  stats?: FeatureStatistics[];
  svsId: string;
  isViewerHelpOpen: boolean;
  isStatisticsHelpOpen: boolean;
  isShareOpen: boolean;
  fileName: string;
};

class ResultPageComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stats: undefined,
      svsId: this.props.svsId,
      isViewerHelpOpen: false,
      isStatisticsHelpOpen: false,
      isShareOpen: false,
      fileName: "",
    };

    this.updateStats = this.updateStats.bind(this);
    this.updateSvsId = this.updateSvsId.bind(this);
    this.statisticsHelpToggle = this.statisticsHelpToggle.bind(this);
    this.viewerHelpToggle = this.viewerHelpToggle.bind(this);
    this.shareToggle = this.shareToggle.bind(this);
    this.updateStats();
  }

  componentDidMount() {
    getPastRuns([this.props.svsId])
      .then((runs) => {
        this.setState({ fileName: runs[0].filename });
      })
      .catch(alert);
  }

  updateSvsId(svsId: string, then?: () => void): void {
    this.setState({ svsId: svsId }, then);
  }

  updateStats(): Promise<void> {
    return csvToFeatureStatistics(this.state.svsId).then((stats) =>
      this.setState({ stats: stats })
    );
  }

  /* TODO: Create a common toggle function */
  statisticsHelpToggle() {
    this.setState({ isStatisticsHelpOpen: !this.state.isStatisticsHelpOpen });
  }

  viewerHelpToggle() {
    this.setState({ isViewerHelpOpen: !this.state.isViewerHelpOpen });
  }

  shareToggle() {
    this.setState({ isShareOpen: !this.state.isShareOpen });
  }

  render() {
    const helpButtonMap: { [index in ViewID]: JSX.Element } = {
      a: createDefaultToolbarButton(
        "Help",
        "bp3-icon-help",
        this.statisticsHelpToggle,
        ""
      ),
      b: createDefaultToolbarButton(
        "Help",
        "bp3-icon-help",
        this.viewerHelpToggle,
        ""
      ),
    };

    const exportMenu = (
      <Menu>
        <ExportMenu id={this.state.svsId} />
      </Menu>
    );

    const ELEMENT_MAP: { [viewID in ViewID]: JSX.Element } = {
      a: (
        <div className={styles.right}>
          <div className={styles.toolbar}>
            <Tag large={true} minimal={true}>
              {this.state.svsId}
            </Tag>
            <ButtonGroup>
              <Popover2 content={exportMenu} placement="auto">
                <Button icon="cloud-download" text="Export" />
              </Popover2>
              <Button icon="share" text="Share" onClick={this.shareToggle} />
            </ButtonGroup>
          </div>
          <RStatistics features={this.state.stats} />
        </div>
      ),
      b: (
        <div className={styles.left}>
          <ImageViewer
            svsId={this.state.svsId}
            updateStats={this.updateStats}
            updateSvsId={this.updateSvsId}
          />
        </div>
      ),
    };

    const TITLE_MAP: { [viewID in ViewID]: string } = {
      a: "Results",
      b: "Whole Slide Image Viewer",
    };

    return (
      <>
        <Mosaic<ViewID>
          renderTile={(id, path) => (
            <MosaicWindow<ViewID>
              title={TITLE_MAP[id]}
              path={path}
              toolbarControls={[helpButtonMap[id], <ExpandButton />]}
            >
              {ELEMENT_MAP[id]}
            </MosaicWindow>
          )}
          resize={{ minimumPaneSizePercentage: 30 }}
          initialValue={{
            direction: "row",
            first: "a",
            second: "b",
            splitPercentage: 45,
          }}
        />
        <Dialog
          isOpen={this.state.isViewerHelpOpen}
          onClose={this.viewerHelpToggle}
          title={"Whole Slide Image Viewer Help"}
          icon={"help"}
        >
          <ViewerHelp />
        </Dialog>
        <Dialog
          isOpen={this.state.isStatisticsHelpOpen}
          onClose={this.statisticsHelpToggle}
          title={"Results Help"}
          icon={"help"}
        >
          <RStatisticsHelp />
        </Dialog>
        <Dialog
          isOpen={this.state.isShareOpen}
          onClose={this.shareToggle}
          title={"Share"}
          icon={"share"}
        >
          <Share svsID={this.state.svsId} />
        </Dialog>
      </>
    );
  }
}

const ResultPage: React.FC<any> = () => {
  const { svsId } = useParams<{ svsId: string }>();
  return <ResultPageComponent svsId={svsId} />;
};

export default ResultPage;
