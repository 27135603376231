import { MenuItem } from "@blueprintjs/core";
import { MouseEvent } from "react";
import {
  FeatureKeyToFeatureClass,
  RegionKeyToRegionClass,
  RegionStatisticToString,
  StatisticToString
} from "../constants/map";
import {
  MapTree,
  MetadataStatistics,
  Reduction,
  WholeSlideStatisticPath
} from "../constants/types";
import { parseCSV } from "./csv";

export function csvKeyToReduction(key?: string): Reduction | undefined {
  if (key === undefined) {
    return undefined;
  }
  switch (key) {
    case "mean":
      return "Mean";
    case "median":
      return "Median";
    case "sum":
      return "Total";
  }
}

export function csvKeyToClassification(key: string): {
  classification: WholeSlideStatisticPath["classification"];
  kind: "feature" | "region" | "metadata";
} {
  if (key in FeatureKeyToFeatureClass) {
    return { classification: FeatureKeyToFeatureClass[key], kind: "feature" };
  } else if (key in RegionKeyToRegionClass) {
    return { classification: RegionKeyToRegionClass[key], kind: "region" };
  } else if (key === "Metadata") {
    return { classification: "Metadata", kind: "metadata" };
  } else {
    throw new Error(`invalid classfication name: ${key}`);
  }
}

export function setMapTree<Leaf>(
  tree: MapTree<Leaf>,
  leaf: Leaf,
  [field, ...fields]: string[]
) {
  let entry = tree.get(field);
  if (entry === undefined) {
    if (fields.length === 0) {
      entry = { leaf };
    } else {
      entry = { children: new Map() };
    }
    tree.set(field, entry);
  }
  const children = (entry as { children: MapTree<Leaf> }).children;
  if (children !== undefined) {
    setMapTree(children, leaf, fields);
  }
}

export function csvKeyToStatName(
  key: string,
  kind: "feature" | "region" | "metadata"
): WholeSlideStatisticPath["statName"] {
  switch (kind) {
    case "feature":
      if (key === "count") {
        return "Count";
      } else {
        return StatisticToString[key];
      }
    case "region":
      if (key === "area_proportion_of_total") {
        return "Area fraction";
      } else {
        return RegionStatisticToString[key];
      }
    case "metadata":
      return key as MetadataStatistics;
  }
}

export function getMapTree<Leaf>(
  tree: MapTree<Leaf>,
  [field, ...fields]: string[]
): Leaf | undefined {
  let entry = tree.get(field);
  if (entry === undefined) {
    return undefined;
  }
  if (fields.length === 0) {
    return (entry as { leaf: Leaf })?.leaf;
  } else {
    const children = (entry as { children: MapTree<Leaf> })?.children;
    return children === undefined ? undefined : getMapTree(children, fields);
  }
}

export function statPathToString(path: WholeSlideStatisticPath): string {
  const statName = path.statName
    .replace(" of Feature", "")
    .replace(" of Region", "");
  if (path.reduction !== undefined) {
    return `${path.reduction} ${path.classification} ${statName}`;
  } else {
    return `${path.classification} ${statName}`;
  }
}

export function statPathToList(path: WholeSlideStatisticPath): string[] {
  if (path.reduction !== undefined) {
    return [path.classification, path.statName, path.reduction];
  } else {
    return [path.classification, path.statName];
  }
}

export async function csvToWholeSlideStatistic(
  svsId: string
): Promise<[MapTree<number>, MapTree<WholeSlideStatisticPath>]> {
  const csv: any = await parseCSV(`/api/whole-slide-statistics/${svsId}`);
  const row = csv.data[0];
  const dataTree = new Map();
  const pathTree = new Map();
  Object.entries(row).forEach(([key, value]) => {
    const keyElems = key.split(":");
    const { classification, kind } = csvKeyToClassification(keyElems[0]);
    const statName = csvKeyToStatName(keyElems[1], kind);
    const reduction = csvKeyToReduction(keyElems[2]);

    const path = {
      classification,
      statName,
      reduction,
    } as WholeSlideStatisticPath;
    const pathElems = statPathToList(path);
    setMapTree(dataTree, Number(value), pathElems);
    setMapTree(pathTree, path, pathElems);
  });
  return [dataTree, pathTree];
}

export function menuTreeToMenuItem(
  map: MapTree<WholeSlideStatisticPath>,
  callback: (
    path: WholeSlideStatisticPath
  ) => (event: MouseEvent<HTMLElement>) => void
): JSX.Element[] {
  const items = [];
  for (const [field, entry] of map) {
    const children = (entry as { children: MapTree<WholeSlideStatisticPath> })
      .children;
    if (children !== undefined) {
      items.push(
        <MenuItem text={field}>
          {menuTreeToMenuItem(children, callback)}
        </MenuItem>
      );
    } else {
      items.push(
        <MenuItem
          text={field}
          onClick={callback((entry as { leaf: WholeSlideStatisticPath }).leaf)}
        />
      );
    }
  }
  return items;
}
