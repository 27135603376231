import {
  Button,
  Callout,
  Classes,
  Collapse,
  Divider,
  H3,
  H5,
  Icon,
  Label,
  MenuItem,
  NonIdealState,
  Switch,
} from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";
import {
  FeatureKeyToFeatureClass,
  StatisticToString,
  StringToStatistic,
} from "../../constants/map";
import {
  FeatureClass,
  FeatureStatistics,
  RegionClass,
  StatisticName,
} from "../../constants/types";
import Adjacency from "../Adjacency";
import AreaDistribution from "../AreaDistribution";
import FeatureCount from "../FeatureCount";
import Scatter from "../Scatter";
import styles from "./style.module.scss";

type Prop = {
  features?: FeatureStatistics[];
};

type State = {
  selectedFeatureClass: FeatureClass;
  selectedXStatistic: StatisticName;
  selectedYStatistic: StatisticName;
  filter: { [key in RegionClass]: boolean };
  isFeatureCountOpen: boolean;
  isXYScatterOpen: boolean;
  isAdjacencyOpen: boolean;
  isAreaDistOpen: boolean;
};

/* Results Statistics Pane */
class RStatistics extends React.Component<Prop, State> {
  private featureDropDownText =
    "Use the drop-down to display graphs for the selected feature";

  constructor(props: Prop) {
    super(props);
    this.state = {
      selectedFeatureClass: "Glomerulus",
      selectedXStatistic: "Area of Maximum Inscribed Circle (μm²)",
      selectedYStatistic: "Area of Feature (μm²)",
      filter: {
        Cortex: true,
        Medulla: false,
        "Non-Kidney": false,
        Transition: false,
        Other: false,
      },
      isFeatureCountOpen: true,
      isXYScatterOpen: true,
      isAdjacencyOpen: false,
      isAreaDistOpen: false,
    };
  }

  regions: RegionClass[] = ["Cortex", "Medulla", "Transition", "Non-Kidney"];

  toggleFeatureCount = () => {
    this.setState({ isFeatureCountOpen: !this.state.isFeatureCountOpen });
  };

  toggleXYScatter = () => {
    this.setState({ isXYScatterOpen: !this.state.isXYScatterOpen });
  };

  toggleAdjacency = () => {
    this.setState({ isAdjacencyOpen: !this.state.isAdjacencyOpen });
  };

  toggleAreaDist = () => {
    this.setState({ isAreaDistOpen: !this.state.isAreaDistOpen });
  };

  render() {
    const selectedFeatureRegions =
      this.props.features?.filter((feature) =>
        this.regions.some(
          (region) => feature.region === region && this.state.filter[region]
        )
      ) ?? [];

    const selectedFeatures =
      selectedFeatureRegions.filter(
        (feature) => feature.classification === this.state.selectedFeatureClass
      ) ?? [];

    const scatterData: { x: number; y: number }[] = selectedFeatures.map(
      (feature: FeatureStatistics, i) => {
        const x = StringToStatistic[this.state.selectedXStatistic];
        const y = StringToStatistic[this.state.selectedYStatistic];
        return { x: feature[x] as number, y: feature[y] as number };
      }
    );

    const FeatureSelect = Select.ofType<string>();
    const renderFeature: ItemRenderer<string> = (
      feature,
      { handleClick, modifiers }
    ) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }

      return (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          onClick={handleClick}
          text={feature}
        />
      );
    };

    const enabledRegions = () => {
      return Object.keys(this.state.filter)
        .filter((region) => this.state.filter[region as RegionClass])
        .join(", ");
    };

    return (
      <div className={styles.statisticsContainer}>
        <H3>Welcome!</H3>
        <p className={Classes.TEXT_MUTED}>
          Here you can make changes to the generated annotations and statistics
          with the interactive <b>Whole Slide Image Viewer</b>. Below, you can
          generate and download graphs from the generated statistics for your
          whole slide image.
        </p>
        <Callout intent="warning" style={{ color: "#A66321" }}>
          Statistics displayed are not necessarily accurate! If any
          classification errors have been made, update the annotations and
          commit the changes to have these changes reflected in the statistics.
        </Callout>
        <H3 style={{ marginTop: "1.125rem" }}>Statistics</H3>
        <div className={styles.regionHeader}>
          <H5 style={{ marginBottom: 0 }}>Region Configuration</H5>
        </div>
        <p className={Classes.TEXT_MUTED}>
          Statistics can be filtered depending on the kidney region that each
          data point lies in. <b>Cortex</b> data points are most likely to
          produce accurate statistics, whereas <b>Non-Kidney</b> data points are
          likely to contain noisy data, and so this should be disabled in
          general.
        </p>
        <div className={styles.regionSwitches}>
          {this.regions.map((region, index) => (
            <>
              <Switch
                className={styles.switch}
                checked={this.state.filter[region]}
                label={region}
                onChange={() =>
                  this.setState(() => {
                    const newFilter = { ...this.state.filter };
                    newFilter[region] = !newFilter[region];
                    return { filter: newFilter };
                  })
                }
                key={index}
                alignIndicator="right"
              />
              {index < this.regions.length - 1 && (
                <Divider className={styles.regionSwitchDivider} />
              )}
            </>
          ))}
        </div>

        {enabledRegions() === "" ? (
          <NonIdealState
            icon="segmented-control"
            title="No Region Selected"
            description="Please select a region from the region configuration dropdown to show statistics"
            className={styles.nonIdealContainer}
          />
        ) : selectedFeatureRegions.length === 0 ? (
          <NonIdealState
            icon="th"
            title="Data Unavailable"
            description="No data is available to compute statistics of the selected region(s)"
            className={styles.nonIdealContainer}
          />
        ) : (
          <div className={styles.graphSummaryContainer}>
            <H5>Summary</H5>
            <Button
              onClick={this.toggleFeatureCount}
              text="Number of Features"
              rightIcon={
                this.state.isFeatureCountOpen ? "chevron-up" : "chevron-down"
              }
              fill={true}
              alignText="left"
            />
            <Collapse isOpen={this.state.isFeatureCountOpen}>
              <FeatureCount features={selectedFeatureRegions} />
            </Collapse>
            <div className={styles.regionHeader}>
              <H5 style={{ marginBottom: 0 }}>Graphs</H5>
              <span>
                <Label
                  className={Classes.INLINE}
                  style={{ margin: 0, display: "inline" }}
                >
                  <span style={{ paddingTop: 2 }}>Feature</span>
                  <FeatureSelect
                    className={styles.featureButton}
                    filterable={false}
                    items={Object.values(FeatureKeyToFeatureClass)}
                    activeItem={this.state.selectedFeatureClass}
                    onItemSelect={(event) =>
                      this.setState({
                        selectedFeatureClass: event as FeatureClass,
                      })
                    }
                    itemRenderer={renderFeature}
                  >
                    <Button
                      text={this.state.selectedFeatureClass}
                      rightIcon="caret-down"
                    />
                  </FeatureSelect>
                </Label>
                <Tooltip2
                  className={styles.tooltip}
                  content={this.featureDropDownText}
                >
                  <Icon size={12} icon="help" />
                </Tooltip2>
              </span>
            </div>
            <p className={Classes.TEXT_MUTED}>
              <b>XY Scatter Plot</b> provides a customisable X and Y axis to
              view the correlation of particular statistics. <br />
              <b>Area Distribution</b> and <b>Adjacency</b> bar plots give some
              insight into the areas of features and distances to other
              features.
            </p>
            <Button
              onClick={this.toggleXYScatter}
              text="XY Scatter Plot"
              rightIcon={
                this.state.isXYScatterOpen ? "chevron-up" : "chevron-down"
              }
              fill={true}
              alignText="left"
            />
            <Collapse
              isOpen={this.state.isXYScatterOpen}
              className={styles.collapse}
            >
              <div className={styles.selectorBar}>
                <FeatureSelect
                  filterable={false}
                  items={Object.values(StatisticToString)}
                  activeItem={this.state.selectedXStatistic}
                  onItemSelect={(event) =>
                    this.setState({
                      selectedXStatistic: event as StatisticName,
                    })
                  }
                  itemRenderer={renderFeature}
                  fill={true}
                >
                  <Button
                    text={this.state.selectedXStatistic}
                    rightIcon="caret-down"
                    icon="arrow-right"
                    fill={true}
                    intent="primary"
                  />
                </FeatureSelect>
                <Divider />
                <FeatureSelect
                  filterable={false}
                  items={Object.values(StatisticToString)}
                  activeItem={this.state.selectedYStatistic}
                  onItemSelect={(event) =>
                    this.setState({
                      selectedYStatistic: event as StatisticName,
                    })
                  }
                  itemRenderer={renderFeature}
                  fill={true}
                >
                  <Button
                    text={this.state.selectedYStatistic}
                    rightIcon="caret-down"
                    icon="arrow-up"
                    fill={true}
                    intent="primary"
                  />
                </FeatureSelect>
              </div>
              <Scatter
                data={scatterData}
                xName={this.state.selectedXStatistic}
                yName={this.state.selectedYStatistic}
                coloured={true}
                height={320}
                feature={this.state.selectedFeatureClass}
              />
            </Collapse>

            <Button
              onClick={this.toggleAreaDist}
              text="Area Distribution"
              rightIcon={
                this.state.isAreaDistOpen ? "chevron-up" : "chevron-down"
              }
              fill={true}
              alignText="left"
            />
            <Collapse
              isOpen={this.state.isAreaDistOpen}
              className={styles.collapse}
            >
              <AreaDistribution features={selectedFeatures} />
            </Collapse>
            <Button
              onClick={this.toggleAdjacency}
              text="Adjacency"
              rightIcon={
                this.state.isAdjacencyOpen ? "chevron-up" : "chevron-down"
              }
              fill={true}
              alignText="left"
            />
            <Collapse
              isOpen={this.state.isAdjacencyOpen}
              className={styles.collapse}
            >
              <Adjacency features={selectedFeatures} />
            </Collapse>
          </div>
        )}
      </div>
    );
  }
}

export default RStatistics;
