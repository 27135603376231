import { Position, Toaster } from "@blueprintjs/core";

export const TopToaster = Toaster.create({
  position: Position.TOP,
});

export const BottomToaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
  usePortal: true,
});
