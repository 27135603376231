import { Classes, Dialog, Divider, H6 } from "@blueprintjs/core";
import styles from "./style.module.scss";
import {
  kidneyCaliperAuthors,
  modelContributors,
  supervisors,
  collaborators,
  departments,
} from "../../constants/credits";
import { ContributorDetails, Department } from "../../constants/types";

const formatContributorList = (contributors: ContributorDetails[]) => (
  <ul className={Classes.LIST}>
    {contributors.map(([name, linkHref, paperTitle]) => (
      <li key={name}>
        {linkHref === undefined ? name : <a href={linkHref}>{name}</a>}
        {paperTitle !== undefined && <i> — “{paperTitle}”</i>}
      </li>
    ))}
  </ul>
);

const formatDepartmentList = (departments: Department[]) =>
  departments.map(({ link, imgLink, deptName, deptInstitution }) => (
    <a href={link} className={styles.department} key={deptName}>
      <img src={imgLink} style={{ height: "6vh" }} alt={deptName} />
      <span>
        {deptName}
        <br />
        {deptInstitution}
      </span>
    </a>
  ));

const CreditsPopup: React.FC<any> = ({ isOpen, onClose }) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={"Authors"}
      style={{ padding: 0 }}
    >
      <div style={{ margin: "1rem" }} role="dialog" aria-label="Credits">
        <H6>
          <b>KidneyCaliper</b> Authors
        </H6>
        {formatContributorList(kidneyCaliperAuthors)}
        <H6>Deep Learning Model Authors</H6>
        {formatContributorList(modelContributors)}
        <H6>Project Supervisors</H6>
        {formatContributorList(supervisors)}
        <H6>Collaborators</H6>
        {formatContributorList(collaborators)}
        <Divider style={{ marginTop: "1.5rem", marginBottom: ".5rem" }} />
        {formatDepartmentList(departments)}
      </div>
    </Dialog>
  );
};

export default CreditsPopup;
