import { ContributorDetails, Department } from "./types";

export const kidneyCaliperAuthors: ContributorDetails[] = [
  ["James Ball", "https://github.com/jameshball"],
  ["Paweł Kroll", "https://github.com/skrroll"],
  ["Jessica Lally", "https://github.com/jessicalally"],
  ["Stefan Radziuk", "https://github.com/stefanradziuk"],
  ["Sudarshan Sreeram", "https://github.com/lambda-shuttle"],
  ["Andy Wang", "https://github.com/cbeuw"],
];

export const modelContributors: ContributorDetails[] = [
  [
    "Jiamin Fu",
    "https://github.com/jm-R152",
    "Mapping Needle Core Biopsies to Kidney Zones using Swin Transformer",
  ],
  [
    "Grzegorz Sarapata",
    "https://github.com/gsarapata",
    "Deep Learning–Based Segmentation and Quantification in Kidney Transplant Pathology",
  ],
];

export const supervisors: ContributorDetails[] = [
  ["Dr Bernhard Kainz", "https://bernhard-kainz.com/"],
  ["Dr Candice Roufosse", "https://www.imperial.ac.uk/people/c.roufosse"],
  ["Callum Arthurs", "https://github.com/c-arthurs"],
];

export const collaborators: ContributorDetails[] = [
  ["Dr Naomi Simmonds (Guy’s and St. Thomas’ NHS Trust)"],
];

export const departments: Department[] = [
  {
    link: "https://biomedia.doc.ic.ac.uk/",
    imgLink: "/assets/biomedia.png",
    deptName: "Biomedical Image Analysis Group, Department of Computing",
    deptInstitution: "Imperial College London",
  },
  {
    link: "https://www.imperial.ac.uk/immunology-inflammation/",
    imgLink: "/assets/immunology_and_inflammation.jpg",
    deptName: "Department of Immunology and Inflammation",
    deptInstitution: "Imperial College London",
  },
];
