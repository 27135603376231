import {
  AnchorButton,
  Card,
  Checkbox,
  Dialog,
  H6,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  ProgressBar,
  Tag,
} from "@blueprintjs/core";
import { ContextMenu2 } from "@blueprintjs/popover2";
import React, { useState } from "react";
import { Run } from "../../constants/types";
import ExportMenu from "../ExportMenu";
import Share from "../Share";
import styles from "./style.module.scss";

/* Props that are common to both variants */
type CommonProps = {
  run: Run;
  variant: "picker" | "standard";
};

type FileRowProps =
  | (CommonProps & {
      resultButtonHidden: boolean;
      queuePosition?: number;
      openMetadata: () => void;
      variant: "standard";
    })
  | (CommonProps & {
      checked: boolean;
      onChange: React.ChangeEventHandler<HTMLInputElement>;
      variant: "picker";
    });

const FileRow: React.FC<any> = (props: FileRowProps) => {
  const [shareOpen, setShareOpen] = useState(false);
  const pipelineFinished = props.run.processing_status === "COMPLETED";

  return (
    <>
      <ContextMenu2
        content={
          <Menu>
            <MenuItem
              text="Export..."
              icon="cloud-download"
              disabled={!pipelineFinished}
            >
              <ExportMenu id={props.run.id} />
            </MenuItem>
            <MenuItem
              icon="share"
              text="Share"
              onClick={() => setShareOpen(true)}
            />
            {props.variant === "standard" && (
              <MenuItem
                icon="media"
                text="Metadata"
                onClick={props.openMetadata}
              />
            )}
            <MenuDivider />
            <MenuItem
              icon="duplicate"
              text="Copy ID"
              onClick={() => navigator.clipboard.writeText(props.run.id)}
            />
            {props.run.original_id !== props.run.id && (
              <MenuItem
                icon="duplicate"
                text="Copy Original ID"
                onClick={() =>
                  navigator.clipboard.writeText(props.run.original_id)
                }
              />
            )}
          </Menu>
        }
      >
        <div className={styles.container}>
          <Card className={styles.row}>
            {props.variant === "picker" && (
              <Checkbox
                onChange={props.onChange}
                checked={props.checked}
                large
                className={styles.checkbox}
              />
            )}
            <div className={styles.thumbnail}>
              <img
                src={`/api/thumbnail/${props.run.original_id}`}
                alt={"thumbnail"}
              />
            </div>

            {/* File name, unique ID, time created and queue position */}
            <div className={styles.information}>
              <H6>{props.run.filename}</H6>
              <Tag minimal={true}>{props.run.id}</Tag>
              <p>
                {((props.variant === "standard" &&
                  props.queuePosition === undefined) ||
                  props.variant === "picker") &&
                  props.run.time_created.toLocaleString()}
                {props.variant === "standard" &&
                  props.queuePosition !== undefined &&
                  `Queue Position: ${props.queuePosition}`}
                {props.run.original_id !== props.run.id && `  •  (Edited)`}
              </p>
            </div>

            {/* Progress Bar */}
            {!pipelineFinished && (
              <div className={styles.progress}>
                <ProgressBar
                  value={props.run.progress}
                  intent={pipelineFinished ? Intent.SUCCESS : Intent.PRIMARY}
                  animate={!pipelineFinished}
                />
              </div>
            )}

            {/* Result Button */}
            {(props.variant === "standard" && props.resultButtonHidden) || (
              <AnchorButton
                className={styles.buttonResult}
                disabled={!pipelineFinished}
                href={`/result/${props.run.id}`}
                target="_blank"
              >
                See result
              </AnchorButton>
            )}
          </Card>
        </div>
      </ContextMenu2>
      <Dialog
        isOpen={shareOpen}
        onClose={() => setShareOpen(false)}
        title={"Share"}
        icon={"share"}
      >
        <Share svsID={props.run.id} />
      </Dialog>
    </>
  );
};

export default FileRow;
