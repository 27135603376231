import { AnchorButton, Card, Icon, InputGroup } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import styles from "./style.module.scss";

function PrivateField(props: {
  identifier: string | undefined;
  handleInputChange: (e: any) => void;
}) {
  return (
    <Card className={styles.card} elevation={0}>
      <div className={styles.header}>
        <div>View results of private submission</div>
        <Tooltip2
          className={styles.tooltip}
          content={"Enter the unique submission identifier to view results"}
        >
          <Icon size={12} icon="help" />
        </Tooltip2>
      </div>
      <div className={styles.input}>
        <InputGroup
          placeholder="Private ID"
          fill={true}
          leftIcon="key"
          onChange={props.handleInputChange}
        />
      </div>
      <div className={styles.button}>
        <AnchorButton
          disabled={props.identifier === undefined}
          text={"See Result"}
          href={`/result/${props.identifier}`}
          icon="eye-open"
          fill={true}
        />
      </div>
    </Card>
  );
}

export default PrivateField;
