import {
  Button,
  Callout,
  Classes,
  Divider,
  H3,
  Menu,
  mergeRefs,
  NonIdealState,
  Position,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";
import {
  MapTree,
  WholeSlideStatisticPath,
  WholeSlideStats,
} from "../../constants/types";
import {
  getMapTree,
  menuTreeToMenuItem,
  statPathToList,
  statPathToString,
} from "../../utils/crossComparison";
import Scatter from "../Scatter";
import styles from "./style.module.scss";

type Prop = {
  slideStats: WholeSlideStats[];
  pathTree: MapTree<WholeSlideStatisticPath>;
};

type State = {
  selectedXStatistic: WholeSlideStatisticPath;
  selectedYStatistic: WholeSlideStatisticPath;
  XMenu: JSX.Element;
  YMenu: JSX.Element;
};

/* Cross Comparison Statistics Pane */
class CCStatistics extends React.Component<Prop, State> {
  constructor(props: Prop) {
    super(props);

    this.selectXAxisStatistic = this.selectXAxisStatistic.bind(this);
    this.selectYAxisStatistic = this.selectYAxisStatistic.bind(this);

    this.state = {
      selectedXStatistic: {
        classification: "Glomerulus",
        statName: "Area of Feature (μm²)",
        reduction: "Mean",
      },
      selectedYStatistic: {
        classification: "Artery",
        statName: "Area of Feature (μm²)",
        reduction: "Mean",
      },
      XMenu: (
        <Menu>
          {menuTreeToMenuItem(props.pathTree, this.selectXAxisStatistic)}
        </Menu>
      ),
      YMenu: (
        <Menu>
          {menuTreeToMenuItem(props.pathTree, this.selectYAxisStatistic)}
        </Menu>
      ),
    };
  }

  selectXAxisStatistic(path: WholeSlideStatisticPath) {
    return () => {
      this.setState({ selectedXStatistic: path });
    };
  }

  selectYAxisStatistic(path: WholeSlideStatisticPath) {
    return () => {
      this.setState({ selectedYStatistic: path });
    };
  }

  render() {
    const xData = this.props.slideStats.map((stats) =>
      getMapTree(stats, statPathToList(this.state.selectedXStatistic))
    );
    const yData = this.props.slideStats.map((stats) =>
      getMapTree(stats, statPathToList(this.state.selectedYStatistic))
    );
    const scatterData = xData
      .map((xValue, i) => {
        const yValue = yData[i];
        return {
          x: xValue,
          y: yValue,
        };
      })
      .filter(({ x, y }) => x !== undefined && y !== undefined) as {
      x: number;
      y: number;
    }[];
    const xName = statPathToString(this.state.selectedXStatistic);
    const yName = statPathToString(this.state.selectedYStatistic);

    return (
      <div className={styles.container}>
        <H3>Cross Comparison</H3>
        <p className={Classes.TEXT_MUTED}>
          Here, you can visualise trends in the statistics generated for your
          submitted and processed whole slide images. To get started, please
          select files from the <b>file select</b> pane to compare and configure
          the chart axes using the dropdowns to your preference.
        </p>
        <Callout intent="warning" style={{ marginBottom: "0.5rem" }}>
          Please note that if you've marked your files private, they will not
          show up on the file select pane! Unfortunately, inclusion of private
          files in cross comparison isn't supported at the moment.
        </Callout>
        {scatterData.length === 0 ? (
          <NonIdealState
            icon="multi-select"
            title="No File Selected"
            description="Please select a file from the file select pane to show statistics"
            className={styles.nonIdealContainer}
          />
        ) : (
          <div>
            <div className={styles.selectorBar}>
              <Popover2
                content={this.state.XMenu}
                position={Position.BOTTOM}
                fill={true}
                renderTarget={({ isOpen, ref, ...p }) => (
                  <Button
                    {...p}
                    text={xName}
                    rightIcon="caret-down"
                    icon="arrow-right"
                    fill={true}
                    intent="primary"
                    active={isOpen}
                    elementRef={mergeRefs(ref)}
                  />
                )}
              />
              <Divider />
              <Popover2
                content={this.state.YMenu}
                position={Position.BOTTOM}
                fill={true}
                renderTarget={({ isOpen, ref, ...p }) => (
                  <Button
                    {...p}
                    text={yName}
                    rightIcon="caret-down"
                    icon="arrow-up"
                    fill={true}
                    intent="primary"
                    active={isOpen}
                    elementRef={mergeRefs(ref)}
                  />
                )}
              />
            </div>
            <Scatter
              data={scatterData}
              xName={xName}
              yName={yName}
              height={400}
              coloured={false}
            />
          </div>
        )}
      </div>
    );
  }
}
export default CCStatistics;
