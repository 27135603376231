import { StatisticToString } from "../../constants/map";
import styles from "./style.module.scss";

/* Note: Annotorious doesn't have support for TypeScript, hence the use of the
 * any type everywhere */

export function MeasurementWidget(args) {
  /* Extract all comments */
  const all = args.annotation
    ? args.annotation.bodies.filter(
        (body) => body.type === "TextualBody" && body.purpose === "commenting"
      )
    : [];

  const container = document.createElement("div");
  container.className = styles.container;

  const table = document.createElement("table");
  table.className = `bp3-html-table bp3-html-table-bordered
                     bp3-html-table-striped bp3-interactive`;

  /* Construct table heading */
  const thead = document.createElement("thead");
  const tbody = document.createElement("tbody");
  const headingRow = document.createElement("tr");
  const measurementsHeading = document.createElement("th");
  const valuesHeading = document.createElement("th");
  measurementsHeading.textContent = "Measurements";
  valuesHeading.textContent = "Values";
  headingRow.appendChild(measurementsHeading);
  headingRow.appendChild(valuesHeading);
  thead.appendChild(headingRow);

  /* Construct table body */
  all.forEach((body) => {
    const row = document.createElement("tr");
    const measurementsField = document.createElement("td");
    const valueField = document.createElement("td");

    /* Extract the measurement label and value; the label is prettified and the
     * value is rounded */
    const fields = body.value.split(":");
    measurementsField.textContent = StatisticToString[fields[0]];
    valueField.textContent = Number.parseFloat(fields[1]).toPrecision(3);

    row.appendChild(measurementsField);
    row.appendChild(valueField);
    tbody.appendChild(row);
  });

  /* Annotorious is a cursed library - they use (incorrect) Regex to detect
   * whether a component is a react functional component and mention a 6-year
   * old StackOverflow post in their sources.
   *
   * Regex: /return .+\.createElement/g
   *
   * The Regex detects whether a "return" appears before ".createElement".
   * Previously, we had an early return for when all was empty. This early
   * return caused the component to be treated as a React Functional component
   * by Annotorious in the production environment! */

  if (all.length === 0) {
    /* Don't create the table if there's no measurements */
    const text = document.createElement("p");
    text.textContent = "No Measurements";
    container.appendChild(text);
  } else {
    table.appendChild(thead);
    table.appendChild(tbody);
    container.appendChild(table);
  }

  return container;
}
